function Testimonials(el) {
  const leftButton = el.querySelector(".testimonials__next-button");
  const rightButton = el.querySelector(".testimonials__previous-button");
  const slidesContainer = el.querySelector(".testimonials");
  const testimonials = el.querySelectorAll(".testimonial");
  const numberOfTestimonials = testimonials.length;

  const breadcrumbs = el.querySelectorAll(".testimonials__breadcrumb");
  let selectedIndex = 0;

  leftButton.addEventListener("click", () => {
    if (slidesContainer.scrollLeft === 0) {
      slidesContainer.scrollLeft += (numberOfTestimonials - 1) * slidesContainer.clientWidth;
      selectedIndex = numberOfTestimonials - 1;
    } else {
      slidesContainer.scrollLeft -= slidesContainer.clientWidth;
      selectedIndex -= 1;
    }
    updateBreadcrumbs();
  });
  
  rightButton.addEventListener("click", () => {
    if (isScrolledAllTheWayRight()) {
      slidesContainer.scrollLeft = 0;
      selectedIndex = 0;
    } else {
      slidesContainer.scrollLeft += slidesContainer.clientWidth;
      selectedIndex += 1;
    }
    updateBreadcrumbs();
  });

  breadcrumbs.forEach((breadcrumb, index) => {
    breadcrumb.addEventListener("click", () => {
      slidesContainer.scrollLeft = index * slidesContainer.clientWidth;
      selectedIndex = index;
      updateBreadcrumbs();
    });
  });

  const observer = new IntersectionObserver(
    (entries) => {
      const activated = entries.reduce((max, entry) =>
        (entry.intersectionRatio > max.intersectionRatio) ? entry : max
      )
      if (activated.intersectionRatio >= 1) {
        selectedIndex = Number(activated.target.getAttribute("data-index"));
        updateBreadcrumbs();
      }
    },
    { root: slidesContainer, threshold: 1 }
  );

  testimonials.forEach((testimonial) => {
    observer.observe(testimonial);
  });
  
  function isScrolledAllTheWayRight() {
    return slidesContainer.scrollLeft + slidesContainer.clientWidth >= slidesContainer.scrollWidth;
  }

  function updateBreadcrumbs() {
    breadcrumbs.forEach((breadcrumb, index) => {
      if (index === selectedIndex) {
        breadcrumb.classList.add("testimonials__breadcrumb--active");
      } else {
        breadcrumb.classList.remove("testimonials__breadcrumb--active");
      }
    });
  }
}

export default Testimonials;