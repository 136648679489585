// Animation T
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

function navBarSlideDown() {
  const timeline = gsap.timeline();

  timeline
    .from('#t1__nav-bar', 0.5, {
      y: '-=36'
    }, '-=0.4')
    .staggerFrom('.t1__page-1__product-tile', 1.6, {
      opacity: 0,
      y: '+=10',
      ease: 'Expo.easeOut'
    }, -0.1)
    .from('#t1__page-1__banner', 0.75, {
      y: '-=81'
    }, '-=2');

  return timeline;
}

function clickProduct() {
  const timeline = gsap.timeline();

  timeline
    .fromTo('#t1__cursor', 1, {
      x: -16,
      y: 210
    }, {
      x: 132,
      y: 130
    });

  return timeline;
}

function pageOneFadeOut() {
  const timeline = gsap.timeline();

  timeline
    .to('#t1__page-1__banner, .t1__page-1__product-tile--primary rect, .t1__page-1__product-tile--primary path, .t1__page-1__product-tile--secondary, #t1__cursor', 0.5, {
      opacity: 0
    })
    .to('.t1__page-1__product-tile--primary image', 1.5, {
      scale: 1.42,
      transformOrigin: 'top left',
      x: 26,
      y: -33,
      ease: 'Expo.easeOut'
    })
    .to('#t1__bg', 0.25, {
      opacity: 0
    }, '-=1.4');

  return timeline;
}

function pageTwoFadeIn() {
  const timeline = gsap.timeline();

  timeline.staggerFrom('#t1__page-2__bg, #t1__page-2__header, #t1__page-2__product, #t1__page-2__content', 0.5, {
    y: '+=16',
    opacity: 0
  }, 0.1);

  return timeline;
}

function clickButton() {
  const timeline = gsap.timeline();

  timeline
    .to('#t1__cursor', 0.001, {
      opacity: 1
    })
    .fromTo('#t1__cursor', 1, {
      x: 460,
      y: 252
    }, {
      x: 380,
      y: 208
    })
    .to('#t1__page-2__cart-button', 0.25, {
      opacity: 0.4
    }, '-=0.4');

  return timeline;
}

function pageTwoFadeOut() {
  const timeline = gsap.timeline();

  timeline
    .to('#t1__nav-bar', 0.5, {
      y: '-=36'
    }, '+=0.5')
    .staggerTo('#t1__cursor, #t1__page-2__content, .t1__page-1__product-tile--primary, #t1__page-2__product, #t1__page-2__header', 0.5, {
      opacity: 0
    }, '-=0.75');

  return timeline;
}

function pageThreeFadeIn() {
  const timeline = gsap.timeline();

  timeline.from('#t1__page-3', 0.5, {
    y: '+=16',
    opacity: 0
  }, '+=0.5');

  return timeline;
}

function showBadge() {
  const timeline = gsap.timeline();

  timeline.from('#t1__page-3__badge', 0.3, {
    scale: 0.8,
    opacity: 0,
    transformOrigin: '50% 50%'
  }, '+=0.5');

  return timeline;
}

function clearStage() {
  const timeline = gsap.timeline();

  timeline.to('#t1__page-3', 0.5, {
    y: '+=16',
    opacity: 0
  });

  return timeline;
}

const masterTimelineT1 = gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineT1.restart();
    }, 1000);
  }
});

masterTimelineT1
  .add(navBarSlideDown())
  .add(clickProduct(), '-=0.5')
  .add(pageOneFadeOut())
  .add(pageTwoFadeIn(), '-=1')
  .add(clickButton())
  .add(pageTwoFadeOut())
  .add(pageThreeFadeIn())
  .add(showBadge())
  .add(clearStage(), '+=1');

export default masterTimelineT1;
