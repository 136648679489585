// Animation E1
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .fromTo(
      "#e1",
      1,
      {
        opacity: 0,
        x: "-400px",
      },
      {
        opacity: 1,
        x: "0px",
      }
    )
    .from(
      "#e1__title",
      0.4,
      {
        opacity: 0,
      },
      "-=0.2"
    )
    .fromTo(
      "#e1__display-pic",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.2"
    )
    .fromTo(
      "#e1__pattern",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.3"
    );

  return timeline;
}

function productsIn() {
  const timeline =  gsap.timeline();

  timeline.staggerFrom(
    ".e1__tile",
    0.8,
    {
      y: "+=30px",
      opacity: 0,
    },
    0.1
  );

  return timeline;
}

function barsIn() {
  const timeline =  gsap.timeline({
    delay: "-=0.5",
  });

  timeline
    .staggerFromTo(
      "#e1 .bar",
      0.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
      },
      0.1
    )
    .fromTo(
      "#e1__social",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.3"
    )
    .staggerFrom(
      "#e1 #btns .btn",
      0.3,
      {
        opacity: 0,
        y: 10,
      },
      0.1
    );

  return timeline;
}

function tilesFadeOut() {
  const timeline =  gsap.timeline({
    delay: 1,
  });

  timeline.to(".e1__fade-helper", 0.2, {
    opacity: 0,
  });

  return timeline;
}

function zoomTile() {
  const timeline =  gsap.timeline();

  timeline
    .to("#e1__tile-one", 1.2, {
      scale: "1.6",
      x: "160px",
      y: "99px",
      ease: "Expo.easeOut",
    })
    .to(
      "#e1__tile-one__bg",
      0.2,
      {
        scaleX: 7,
        scaleY: 5,
        transformOrigin: "50% 50%",
      },
      "-=1.2"
    );

  return timeline;
}

function pageTwoIn() {
  const timeline =  gsap.timeline({
    delay: "-=1",
  });

  timeline
    .to("#e1__tile-one__bg", 0.3, {
      opacity: 0,
    })
    .from(
      "#e1__page-container",
      0.4,
      {
        opacity: 0,
      },
      "-=0.3"
    )
    .from("#e1__page-title", 0.3, {
      x: -10,
      opacity: 0,
    })
    .from("#e1__page-subtitle", 0.3, {
      x: -10,
      opacity: 0,
    })
    .set("#e1 #btns", {
      opacity: 1,
      x: 360,
      y: 82,
    })
    .set("#e1 #btns .btn", {
      opacity: 0,
      y: "+=10",
    })
    .staggerTo(
      "#e1 #btns .btn",
      0.3,
      {
        opacity: 1,
        y: "0",
      },
      0.1
    );

  return timeline;
}

function clearStage() {
  const timeline =  gsap.timeline({
    delay: 2,
  });

  timeline.to("#e1", 0.4, {
    opacity: 0,
  });

  return timeline;
}

const masterTimelineE1 =  gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineE1.progress(0).play();
    }, 1000);
  },
});

masterTimelineE1
  .add(pageIn())
  .add(productsIn())
  .add(barsIn())
  .add(tilesFadeOut())
  .add(zoomTile())
  .add(pageTwoIn())
  .add(clearStage());

export default masterTimelineE1;
