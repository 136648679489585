/* eslint no-bitwise:0 */

import U from "./Utilities";

class Select {
  constructor(
    el,
    settings = {
      maxVisibleOptions: 4,
      searchTimeout: 500,
      classes: {
        base: "select-input",
        hasFocus: "select-input--has-focus",
        isOpen: "select-input--is-open",
        selection: "select-input__selection",
        input: "select-input__input",
        wrapper: "select-input__wrapper",
        options: "select-input__options",
        option: "select-input__option",
        optionActive: "select-input__option--is-active",
        optionDisabled: "select-input__option--is-disabled",
        optionSelected: "select-input__option--is-selected",
        label: "select-input__label",
        hidden: "select-input__hidden",
      },
    }
  ) {
    if (el.tagName.toLowerCase() !== "select") {
      console.warn("Select Error: Element must be a <select>");

      return false;
    } else if (!el.querySelectorAll("option").length) {
      console.warn("Select Error: <select> must contain at least one <option>");

      return false;
    }

    this.settings = settings;

    this.dom = {
      body: document.body,
      originalSelect: el,
    };

    this.keyCodes = {
      up: 38,
      down: 40,
      return: 13,
      spacebar: 32,
      escape: 27,
      tab: 9,
      alphabet: [
        65,
        66,
        67,
        68,
        69,
        70,
        71,
        72,
        73,
        74,
        75,
        76,
        77,
        78,
        79,
        80,
        81,
        82,
        83,
        84,
        85,
        86,
        87,
        88,
        89,
        90,
      ],
      numbers: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57],
    };

    U.autobind(this);

    this.listeners = {
      close: e => {
        if (
          !this.dom.selection.contains(e.target) ||
          !this.dom.options.contains(e.target)
        ) {
          this._close();
        }
      },
      detach: this._detach,
    };

    this.activeIndex = null;
    this.selectedId = "";
    this.searchString = "";
    this.transitionEnd = U.transitionEnd();

    this._build();
  }

  _build() {
    // Create main and dropdown elements
    this.dom.main = document.createElement("div");
    this.dom.main.classList.add(this.settings.classes.base);

    if (this.settings.countryCodes) {
      this.dom.main.classList.add(this.settings.classes.phone);
    }

    this.dom.dropdown = this.dom.main.cloneNode();
    this.dom.dropdown.style.position = "absolute";
    this.dom.dropdown.style.zIndex = 999;

    // Build input
    this.dom.input = document.createElement("div");
    this.dom.input.classList.add(this.settings.classes.input);
    Select._setId(this.dom.input, "input");

    // Build selection
    this.dom.selection = document.createElement("div");
    this.dom.selection.classList.add(this.settings.classes.selection);

    // Wrap selection, input and originalSelect
    this.dom.selection.appendChild(this.dom.input);
    this.dom.main.appendChild(this.dom.selection);

    this.dom.originalSelect.parentNode.insertBefore(
      this.dom.main,
      this.dom.originalSelect
    );
    this.dom.main.appendChild(this.dom.originalSelect);

    // Hide originalSelect
    this.dom.originalSelect.classList.add(this.settings.classes.hidden);

    // Build wrapper element
    this.dom.wrapper = document.createElement("div");
    this.dom.wrapper.classList.add(this.settings.classes.wrapper);

    // Build options
    this.dom.options = document.createElement("ul");
    this.dom.options.classList.add(this.settings.classes.options);
    Select._setId(this.dom.options, "options");

    // Append options to dropdown
    this.dom.originalSelect.querySelectorAll("option").forEach(el => {
      // Create option element
      const option = document.createElement("li");
      option.classList.add(this.settings.classes.option);

      // Create label elements
      option.innerHTML = el.textContent;

      if (el.selected) {
        option.classList.add(this.settings.classes.optionSelected);
      } else if (el.disabled) {
        option.classList.add(this.settings.classes.optionDisabled);
      }

      Select._setId(option, "option");

      el.setAttribute("data-select-id", option.getAttribute("id"));
      this.dom.options.appendChild(option);
      this._initOptionEvents(option);
    });

    this.dom.option = this.dom.options.querySelectorAll(
      `.${this.settings.classes.option}`
    );

    // Set variant flags
    this.hasScroll =
      this.settings.maxVisibleOptions &&
      this.settings.maxVisibleOptions < this.dom.option.length;

    this.dom.wrapper.appendChild(this.dom.options);
    this.dom.dropdown.appendChild(this.dom.wrapper);

    this._setSelectedOption(
      this.dom.options.querySelector(`.${this.settings.classes.optionSelected}`)
    );

    this._initEvents();
    this._initA11y();
  }

  _initEvents() {
    // Container click event
    this.dom.selection.addEventListener("click", e => {
      e.preventDefault();
      e.stopPropagation();

      this._toggle();
    });

    // Container focus event
    this.dom.selection.addEventListener("focus", () => {
      this.dom.main.classList.add(this.settings.classes.hasFocus);

      document.addEventListener("keydown", this._keyboardControl);
    });

    // Container blur event
    this.dom.selection.addEventListener("blur", () => {
      this.dom.main.classList.remove(this.settings.classes.hasFocus);

      document.removeEventListener("keydown", this._keyboardControl);
    });
  }

  _initOptionEvents(option) {
    const isDisabled = option.classList.contains(
      this.settings.classes.optionDisabled
    );

    // Option click event
    option.addEventListener("mousedown", e => {
      e.preventDefault();
      e.stopPropagation();

      if (!isDisabled) {
        this._setSelectedOption(option);
      }
    });

    // Option mouseenter event
    option.addEventListener("mouseenter", e => {
      e.preventDefault();

      if (!isDisabled) {
        this._setActiveOption(option);
      }
    });
  }

  _initA11y() {
    this.dom.originalSelect.setAttribute("aria-hidden", true);
    this.dom.originalSelect.setAttribute("tabindex", -1);

    this.dom.selection.setAttribute("aria-expanded", false);
    this.dom.selection.setAttribute("aria-haspopup", true);
    this.dom.selection.setAttribute(
      "aria-labelledby",
      this.dom.input.getAttribute("id")
    );
    this.dom.selection.setAttribute(
      "aria-owns",
      this.dom.options.getAttribute("id")
    );
    this.dom.selection.setAttribute("role", "combobox");
    this.dom.selection.setAttribute("tabindex", 0);

    this.dom.input.setAttribute("aria-readonly", true);
    this.dom.input.setAttribute("role", "textbox");

    this.dom.options.setAttribute("aria-expanded", false);
    this.dom.options.setAttribute("role", "tree");

    this.dom.option.forEach(el => {
      el.setAttribute("aria-label", el.textContent);
      el.setAttribute("aria-disabled", el.disabled);
      el.setAttribute("aria-selected", el.selected);
      el.setAttribute("role", "treeitem");
    });
  }

  _toggle() {
    if (this._isOpen()) {
      this._close();
    } else {
      this._open();
    }
  }

  _isOpen() {
    return this.dom.main.classList.contains(this.settings.classes.isOpen);
  }

  _open() {
    // Position and append dropdown
    this._positionDropdown();
    this.dom.body.appendChild(this.dom.dropdown);

    // Set maximum visible options
    if (this.hasScroll) {
      this.dom.wrapper.style.height = `${U.rect(this.dom.option[0]).height *
        this.settings.maxVisibleOptions}px`;
      this._focusOption(
        this.dom.options.querySelector(`[data-select-id=${this.selectedId}]`)
      );
    }

    this.dom.main.classList.add(this.settings.classes.isOpen);
    this.dom.selection.setAttribute("aria-expanded", true);
    this.dom.options.setAttribute("aria-expanded", true);

    U.reflow(this.dom.dropdown, () => {
      this.dom.dropdown.classList.add(this.settings.classes.isOpen);
    });

    document.addEventListener("click", this.listeners.close);
  }

  _close() {
    this.dom.wrapper.addEventListener(this.transitionEnd, this._detach);
    this.dom.dropdown.classList.remove(this.settings.classes.isOpen);

    // Update container & input
    this.dom.main.classList.remove(this.settings.classes.isOpen);
    this.dom.selection.setAttribute("aria-expanded", false);
    this.dom.options.setAttribute("aria-expanded", false);

    document.removeEventListener("click", this.listeners.close);
  }

  _detach() {
    this.dom.body.removeChild(this.dom.dropdown);
    this._unsetActiveOption();

    this.dom.wrapper.removeEventListener(this.transitionEnd, this._detach);
  }

  _positionDropdown() {
    const rect = U.rect(this.dom.main);

    this.dom.dropdown.style.top = `${rect.top + U.scrollPos() + rect.height}px`;
    this.dom.dropdown.style.left = `${rect.left}px`;
    this.dom.dropdown.style.width = `${rect.width}px`;
  }

  _setSelectedOption(option) {
    // Unset previous selected option
    this.dom.option.forEach(opt => {
      opt.classList.remove(this.settings.classes.optionSelected);
    });

    const previousSelection = this.selectedId !== "";

    // Set selected option
    this.selectedId = option.getAttribute("id");
    this.selectedOption = this.dom.originalSelect.querySelector(
      `[data-select-id=${this.selectedId}]`
    );
    this.selectedOption.selected = true;

    if (previousSelection) {
      if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        this.dom.originalSelect.dispatchEvent(evt);
      } else {
        this.dom.originalSelect.fireEvent("onchange");
      }
    }

    this.value = this.selectedOption.value;
    option.classList.add(this.settings.classes.optionSelected);

    // Build label
    let inputLabel = "";

    option.childNodes.forEach(label => {
      inputLabel += label.textContent;
    });

    // Set label
    const flag = document.createElement("span");
    flag.classList.add("flag");
    flag.classList.add(`flag--${this.selectedOption.getAttribute("value")}`);
    this.dom.input.innerHTML = inputLabel;
    this.dom.input.insertBefore(flag, this.dom.input.childNodes[0]);

    this.dom.input.setAttribute(
      "title",
      inputLabel.replace(this.settings.extraLabelSpacer, " ")
    );

    if (this._isOpen()) {
      this._close();
    }
  }

  _stepActiveOption(step) {
    let offset = step(0);

    while (
      this.dom.option[this.activeIndex + offset].classList.contains(
        this.settings.classes.optionDisabled
      )
    ) {
      offset = step(offset);
    }

    this._setActiveOption(this.dom.option[this.activeIndex + offset], true);
  }

  _setActiveOption(option) {
    this._unsetActiveOption();

    this.activeIndex = U.index(option, this.dom.option);
    option.classList.add(this.settings.classes.optionActive);
    this.dom.selection.setAttribute(
      "aria-activedescendant",
      option.getAttribute("id")
    );

    this._focusOption(option);
  }

  _focusOption(option) {
    if (
      this.hasScroll &&
      (option.offsetTop >
        this.dom.wrapper.offsetHeight + this.dom.wrapper.scrollTop ||
        option.offsetTop < this.dom.wrapper.scrollTop)
    ) {
      this.dom.wrapper.scrollTop = option.offsetTop;
    }
  }

  _unsetActiveOption() {
    this.dom.option.forEach(option => {
      option.classList.remove(this.settings.classes.optionActive);
    });

    this.dom.selection.removeAttribute("aria-activedescendant");
    this.activeIndex = null;
  }

  _keyboardControl(e) {
    const keyCode = e.which || e.keyCode || 0;

    if (
      this.keyCodes.alphabet.indexOf(keyCode) > -1 ||
      this.keyCodes.numbers.indexOf(keyCode) > -1
    ) {
      window.clearTimeout(this.searchTimer);

      this.searchTimer = window.setTimeout(() => {
        this.searchString = "";
      }, this.settings.searchTimeout);

      this._search(keyCode);
    } else {
      switch (keyCode) {
        case this.keyCodes.up: {
          e.preventDefault();

          if (!this._isOpen()) {
            this._open();
          }

          if (this.activeIndex === null) {
            this._setActiveOption(document.getElementById(this.selectedId));
          } else if (this.activeIndex > 0) {
            this._stepActiveOption(offset => {
              return offset - 1;
            });
          }

          break;
        }
        case this.keyCodes.down: {
          e.preventDefault();

          if (!this._isOpen()) {
            this._open();
          }

          if (this.activeIndex === null) {
            this._setActiveOption(document.getElementById(this.selectedId));
          } else if (this.activeIndex < this.dom.option.length - 1) {
            this._stepActiveOption(offset => {
              return offset + 1;
            });
          }

          break;
        }
        case this.keyCodes.return:
        case this.keyCodes.spacebar: {
          e.preventDefault();

          if (this._isOpen()) {
            const selected = this.dom.options.querySelector(
              `.${this.settings.classes.optionActive}`
            );

            if (selected) {
              this._setSelectedOption(
                this.dom.options.querySelector(
                  `.${this.settings.classes.optionActive}`
                )
              );
            } else {
              this._close();
            }
          } else {
            this._open();
            this._setActiveOption(document.getElementById(this.selectedId));
          }

          break;
        }
        case this.keyCodes.escape: {
          e.preventDefault();

          if (this._isOpen()) {
            this._close();
          }

          break;
        }
        default: {
          break;
        }
      }
    }
  }

  _search(keyCode) {
    let match = false;
    this.searchString += String.fromCharCode(keyCode).toLowerCase();

    this.dom.option.forEach(option => {
      if (!option.classList.contains(this.settings.classes.optionDisabled)) {
        option.childNodes.forEach(label => {
          const labelText = label.textContent;

          if (
            labelText.substring(0, this.searchString.length).toLowerCase() ===
            this.searchString
          ) {
            if (this._isOpen()) {
              this._setActiveOption(option);
            } else {
              this._setSelectedOption(option);
            }

            match = true;
          }
        });
      }

      return !match;
    });
  }

  static _setId(el, label) {
    const id = `select-${Select._hashElement(el)}-${label}`;

    el.setAttribute("id", id);
    el.setAttribute("data-select-id", id);
  }

  static _hashElement(el) {
    let hash = 0;
    const str = el.outerHTML + el.innerHTML + new Date().getTime();

    if (!str.length) {
      return hash;
    }

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const hex = (hash & 0x00ffffff).toString(16);

    return "00000".substring(0, 6 - hex.length) + hex;
  }

  destroy() {
    this.dom.selection.parentNode.insertBefore(
      this.dom.originalSelect,
      this.dom.selection
    );
    this.dom.selection.parentNode.removeChild(this.dom.selection);
    this.dom.originalSelect.removeAttribute("class");
    this.dom.originalSelect.removeAttribute("aria-hidden");
    this.dom.originalSelect.removeAttribute("tabindex");
  }
}

export default Select;
