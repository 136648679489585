// Animation A1
// import TimelineLite from "gsap/umd/TimelineLite";
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

function boxIn() {
  const timeline = gsap.timeline();

  timeline.fromTo(
    "#a1__search-box",
    0.6,
    {
      scaleX: 0,
    },
    {
      scaleX: 1,
    }
  );

  return timeline;
}

function iconIn() {
  const timeline = gsap.timeline();

  timeline.fromTo(
    "#a1__search-icon",
    0.6,
    {
      opacity: 0,
    },
    {
      opacity: 1,
    }
  );

  return timeline;
}

function typing() {
  const timeline = gsap.timeline();

  timeline
    .fromTo(
      "#a1__search-text, #a1__text-shield-container",
      0.0001,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    )
    .staggerFromTo(
      ".a1__text-shield",
      0.1,
      {
        opacity: 1,
      },
      {
        opacity: 0,
      },
      0.08
    );

  return timeline;
}

function cursorBlink() {
  const timeline = gsap.timeline();

  timeline
    .fromTo(
      "#a1__type-cursor",
      0.1,
      {
        opacity: 0,
        ease: "Linear.easeNone",
      },
      {
        opacity: 1,
        repeat: 3,
        repeatDelay: 0.3,
        onComplete: timeline.reverse.bind(timeline),
        onReverseComplete: timeline.play.bind(timeline),
      }
    )
    .to("#a1__type-cursor", 0.1, {
      opacity: 0,
      delay: 0.3,
    });

  return timeline;
}

function searchBarShrink() {
  const timeline = gsap.timeline();

  timeline.to("#a1__search-group", 1, {
    x: "16px",
    y: "-100px",
    scale: 0.7,
    ease: "Power3.easeOut",
  });

  return timeline;
}

function tilesIn() {
  const timeline = gsap.timeline();

  timeline
    .staggerFrom(
      ".a1__product-tile",
      0.6,
      {
        y: "+=20px",
        opacity: 0,
      },
      0.1
    )
    .to(".a1__product-tile, #a1__search-group", 1.6, {
      y: "-=90px",
    })
    .to(".a1__product-tile", 1.2, {
      y: "-=210px",
    })
    .to(".a1__product-tile-secondary", 0.5, {
      opacity: 0,
    })
    .to(".a1__product-tile-primary", 1, {
      scale: 1.5,
      transformOrigin: "50% 50%",
      x: "168px",
      y: "50%",
      ease: "Back.easeOut",
    })
    .to(".a1__product-tile-primary__display-pic", 0.2, {
      fill: "rgb(0,118,255)",
    })
    .staggerFromTo(
      ".a1__product-tile-primary__bars",
      0.3,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
      },
      0.1
    );

  return timeline;
}

function clearStage() {
  const timeline = gsap.timeline({
    delay: 3,
  });

  timeline.to(".a1__product-tile-primary", 0.6, {
    opacity: 0,
  });

  return timeline;
}

const masterTimelineA1 = gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineA1.progress(0).play();
    }, 1000);
  },
});

masterTimelineA1
  .add(boxIn())
  .add(iconIn())
  .add(typing(), "+=0.1")
  .add(cursorBlink())
  .add(searchBarShrink())
  .add(tilesIn())
  .add(clearStage());

export default masterTimelineA1;
