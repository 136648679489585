// Animation J
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .from("#j1__bg", 0.8, {
      scaleY: 0,
    })
    .from("#j1__header-bar", 0.3, {
      y: -36,
    })
    .staggerFrom(
      ".j1__tile",
      0.4,
      {
        opacity: 0,
        y: "+=10",
      },
      0.1
    )
    .from("#j1__bell-curve", 0.9, {
      scaleY: 0.7,
      opacity: 0,
      transformOrigin: "50% 100%",
      ease: "Expo.easeOut",
    })
    .from(
      "#j1__bell-line-l",
      0.9,
      {
        x: "-=40",
        ease: "Expo.easeOut",
      },
      "-=0.9"
    )
    .from(
      "#j1__bell-line-r",
      0.9,
      {
        x: "+=40",
        ease: "Expo.easeOut",
      },
      "-=0.9"
    )
    .staggerFrom(
      ".j1__graph-row",
      0.5,
      {
        scaleX: 0,
        transformOrigin: "0% 50%",
      },
      0.1
    )
    .to(
      "#j1__map",
      0.8,
      {
        fill: "#1579ED",
      },
      "+=0.01"
    );

  return timeline;
}

function pieIn() {
  const timeline =  gsap.timeline();

  timeline.from("#j1__pie", 0.8, {
    opacity: 0,
    rotation: -50,
    transformOrigin: "50% 50%",
  });

  return timeline;
}

const masterTimelineJ1 =  gsap.timeline({
  paused: true,
});

masterTimelineJ1.add(pageIn()).add(pieIn());

export default masterTimelineJ1;
