// Animation H
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .set("#h1__img", {
      opacity: 0,
      scale: 0.95,
      transformOrigin: "50% 100%",
    })
    .from("#h1__bg", 0.5, {
      scaleY: 0,
    })
    .from(
      "#h1__logo",
      0.3,
      {
        opacity: 0,
      },
      "-=0.2"
    )
    .from(
      "#h1__title",
      0.3,
      {
        x: "-=10",
        opacity: 0,
      },
      "-=0.2"
    )
    .from(
      "#h1__subtitle",
      0.3,
      {
        x: "-=10",
        opacity: 0,
      },
      "-=0.2"
    )
    .from(
      "#h1__module-1 #bg, #h1__module-2 #bg ",
      0.3,
      {
        scaleX: 0,
      },
      "-=0.2"
    )
    .from("#h1__module-1 g, #h1__module-2 *", 0.3, {
      opacity: 0,
    })
    .staggerFrom(
      "#h1__row-container-1 > .h1__row",
      0.3,
      {
        scaleX: 0,
      },
      0.05
    )
    .staggerFrom(
      "#h1__row-container-2 > .h1__row",
      0.3,
      {
        scaleX: 0,
      },
      0.05
    )
    .to("#h1__img", 0.6, {
      opacity: 1,
      scale: 1,
    });

  return timeline;
}

const masterTimelineH1 =  gsap.timeline({
  paused: true,
});

masterTimelineH1.add(pageIn());

export default masterTimelineH1;
