import { gsap } from "gsap";
import U from "./Utilities";

class Navigator {
  constructor(el) {
    U.autobind(this);

    this.dom = {
      el,
      inner: el.querySelector(".navigator__inner"),
      tray: el.querySelector(".navigator__tray"),
      dots: el.querySelectorAll(".navigator__dot"),
      indicator: el.querySelector(".navigator__indicator"),
      buttons: el.querySelectorAll(".navigator__button"),
      targets: [],
      headerBar: document.getElementById("header-bar"),
    };

    this.activeIndex = null;

    this.dom.inner.addEventListener("mouseenter", this.show);

    this.dom.buttons.forEach((button, i) => {
      const target = document.getElementById(
        button.getAttribute("data-target")
      );

      if (target) {
        this.dom.targets.push(target);

        button.addEventListener("click", e => {
          e.preventDefault();

          this.scrollTo(target);
        });

        this.dom.dots[i].addEventListener("click", () => {
          this.scrollTo(target);
        });
      }
    });
  }

  show() {
    gsap.to(this.dom.tray, 0.75, {
      xPercent: 100,
      ease: "Power3.easeOut",
    });

    this.dom.buttons.forEach((button, i) => {
      button.timeout = window.setTimeout(() => {
        button.classList.add("navigator__button--is-visible");
      }, i * 75 + 200);
    });

    this.dom.inner.removeEventListener("mouseenter", this.show);
    this.dom.el.addEventListener("mouseleave", this.hide);
  }

  hide() {
    this.dom.buttons.forEach(button => {
      window.clearTimeout(button.timeout);
      button.classList.remove("navigator__button--is-visible");
    });

    gsap.to(this.dom.tray, 0.5, {
      xPercent: 0,
      ease: "Power1.easeIn",
    });

    this.dom.el.removeEventListener("mouseleave", this.hide);
    this.dom.inner.addEventListener("mouseenter", this.show);
  }

  scrollTo(target) {
    const scrollPos = U.scrollPos();
    const maxScroll = document.body - window.outerHeight;
    let targetPos = U.rect(target).top + scrollPos - 48;

    if (targetPos < scrollPos) {
      targetPos -= U.rect(this.dom.headerBar).height;
    }

    const duration = Math.min(Math.abs(targetPos - scrollPos) / 1000, 1.5);

    gsap.to(window, duration, {
      scrollTo: targetPos > maxScroll ? maxScroll : targetPos,
      ease: "Power2.easeInOut",
    });
  }

  update() {
    let activeIndex = 0;

    this.dom.targets.forEach((target, i) => {
      if (U.rect(target).top < window.innerHeight / 2) {
        activeIndex = i;
      }
    });

    if (activeIndex !== this.activeIndex) {
      this.activeIndex = activeIndex;

      gsap.to(this.dom.indicator, 0.25, {
        y: this.dom.dots[this.activeIndex].offsetTop,
        ease: "Power2.easeInOut",
        onComplete: () => {
          this.dom.indicator.classList.add("navigator__indicator--is-visible");
        },
      });
    }
  }
}

export default Navigator;
