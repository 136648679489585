// Animation Q
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

var masterTimelineQ1 = gsap.timeline({ repeat: -1, repeatDelay: 1 });
masterTimelineQ1
  .add(q1__panelsFadeIn())
  .add(q1__pageUp())
  .add(q1__pulse())
  .add(q1__pageIn())
  .add(q1__clearStage(), "+=2");

function q1__panelsFadeIn() {
  var tl = gsap.timeline();
  tl.staggerFrom(
    "#q1__row-01, #q1__row-02, #q1__row-03, #q1__row-04",
    1.2,
    { opacity: 0 },
    0.1
  );
  return tl;
}

function q1__pageUp() {
  var tl = gsap.timeline();
  tl.to("#q1__page-01", 1.5, { y: "-=90" });
  tl.to("#q1__page-01", 2, { y: "-=120" });
  return tl;
}

function q1__pulse() {
  var tl = gsap.timeline({ delay: 1 });
  tl.fromTo(
    "#q1__controls__pulse > #q1__top-layer",
    0.5,
    { scale: 0.8, opacity: 0, transformOrigin: "50% 50%" },
    { scale: 1.6, opacity: 1 }
  );
  tl.to("#q1__controls__pulse > #q1__top-layer", 0.4, {
    scale: 0.9,
    opacity: 0,
  });
  tl.fromTo(
    "#q1__controls__pulse > #q1__bottom-layer",
    0.3,
    { scale: 0.9, opacity: 0, transformOrigin: "50% 50%" },
    { scale: 1.5, opacity: 1 },
    "-=0.8"
  );
  tl.to(
    "#q1__controls__pulse > #q1__bottom-layer",
    0.2,
    { scale: 0.9, opacity: 0 },
    "-=0.2"
  );
  tl.to("#q1__page-01", 0.2, { opacity: 0 });
  return tl;
}

function q1__pageIn() {
  var tl = gsap.timeline();
  tl.from("#q1__p2__header", 0.2, { opacity: 0 });
  tl.from("#q1__p2__sub-header", 0.4, { opacity: 0 });
  tl.staggerFrom(
    "#q1__p2__p-01, #q1__p2__p-02, #q1__p2__p-03, #q1__p2__p-04, #q1__p2__p-05, #q1__p2__p-06",
    1.6,
    { opacity: 0 },
    0.1
  );
  return tl;
}

function q1__clearStage() {
  var tl = gsap.timeline();
  tl.to("#q1__container", 0.5, { opacity: 0 });
  return tl;
}

const masterTimelineA1 = gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineA1.progress(0).play();
    }, 1000);
  },
});

export default masterTimelineQ1;
