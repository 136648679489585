// Animation C
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .from("#c1__header-bar", 0.3, {
      y: -36,
    })
    .from(
      "#c1__page-title",
      0.3,
      {
        opacity: 0,
        y: "+=10",
      },
      "-=0.2"
    )
    .from(
      "#c1__tile-1",
      0.3,
      {
        opacity: 0,
        y: "+=10",
      },
      "-=0.2"
    )
    .from(
      "#c1__tile-2",
      0.3,
      {
        opacity: 0,
        y: "+=10",
      },
      "-=0.2"
    );

  return timeline;
}

function barsUp() {
  const timeline =  gsap.timeline();

  timeline
    .staggerFrom(
      "#c1 #col-light rect",
      0.4,
      {
        scaleY: 0,
        transformOrigin: "bottom center",
      },
      0.05
    )
    .staggerFrom(
      "#c1 #col-dark rect",
      0.4,
      {
        scaleY: 0,
        transformOrigin: "bottom center",
      },
      0.05
    );

  return timeline;
}

const masterTimelineC1 =  gsap.timeline({
  paused: true,
});

masterTimelineC1.add(pageIn()).add(barsUp());

export default masterTimelineC1;
