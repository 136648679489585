// Animation O
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .from("#o1__header-bg", 0.4, {
      scaleX: 0,
    })
    .from(
      "#o1__header-logo",
      0.6,
      {
        opacity: 0,
      },
      "-=0.3"
    )
    .from(
      "#o1__header-title",
      0.5,
      {
        opacity: 0,
        x: "-=10",
      },
      "-=0.4"
    )
    .from(
      "#o1__header-row",
      0.5,
      {
        opacity: 0,
        x: "-=10",
      },
      "-=0.4"
    );

  return timeline;
}

function chatOne() {
  const timeline =  gsap.timeline({
    delay: 1,
  });

  timeline
    .set("#o1__bubble-1", {
      y: 187,
    })
    .from("#o1__bubble-1__dp", 0.4, {
      opacity: 0,
    })
    .from(
      "#o1__bubble-1 .row-l",
      0.4,
      {
        scaleX: 0,
      },
      "-=0.4"
    )
    .from("#o1__bubble-1__msg", 0.8, {
      y: "+=10",
      opacity: 0,
    })
    .to(
      "#o1__bubble-1",
      0.8,
      {
        y: 88,
      },
      "+=1"
    );

  return timeline;
}

function chatTwo() {
  const timeline =  gsap.timeline();

  timeline
    .from("#o1__bubble-2__dp", 0.4, {
      opacity: 0,
    })
    .from(
      "#o1__bubble-2 .row-r",
      0.4,
      {
        scaleX: 0,
        transformOrigin: "100% 50%",
      },
      "-=0.4"
    )
    .from("#o1__bubble-2__msg", 0.4, {
      y: "+=10",
      opacity: 0,
    });

  return timeline;
}

const masterTimelineO1 =  gsap.timeline({
  paused: true,
});

masterTimelineO1
  .add(pageIn())
  .add(chatOne())
  .add(chatTwo(), "-=0.5");

export default masterTimelineO1;
