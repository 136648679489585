// Animation G
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();
  timeline
    .from(
      "#g1__bar #bg",
      0.2,
      {
        scaleY: 0,
      },
      "-=0.2"
    )
    .from(
      "#g1__title",
      0.3,
      {
        x: "-=10",
        opacity: 0,
      },
      "-=0.2"
    )
    .from(
      "#g1__bar > #g1__row",
      0.3,
      {
        x: "-=10",
        opacity: 0,
      },
      "-=0.2"
    )
    .staggerFrom(
      ".g1__product-tile > #bg",
      0.4,
      {
        scaleY: 0,
      },
      "-=0.2"
    )
    .staggerFrom(
      ".g1__product-tile > #text",
      0.4,
      {
        scaleX: 0,
      },
      0.1
    )
    .staggerFrom(
      "#g1__row-container > .g1__row",
      0.3,
      {
        scaleX: 0,
      },
      0.05
    )
    .from(
      "#g1__social",
      0.3,
      {
        opacity: 0,
      },
      "-=0.2"
    )
    .from(
      "#g1__pattern",
      0.3,
      {
        opacity: 0,
      },
      "-=0.2"
    )
    .from(
      "#g1__logo",
      0.3,
      {
        opacity: 0,
      },
      "-=0.2"
    )
    .staggerFrom(
      ".g1__product-tile > #img",
      0.5,
      {
        opacity: 0,
      },
      0.1
    )
    .from(
      "#g1__verified-badge-sm",
      0.5,
      {
        opacity: 0,
      },
      "-=0.4"
    )
    .from(
      "#g1__verified-shadow",
      0.5,
      {
        opacity: 0,
      },
      "-=0.4"
    )
    .from(
      "#g1__verified-badge-lg",
      0.5,
      {
        opacity: 0,
      },
      "-=0.4"
    );

  return timeline;
}

function spinBadge() {
  const timeline =  gsap.timeline();

  timeline.fromTo(
    "#g1__verified-badge-lg__shield",
    30,
    {
      rotation: 0,
      transformOrigin: "50% 50%",
    },
    {
      rotation: 360,
      transformOrigin: "50% 50%",
      ease: "Linear.easeNone",
    }
  );

  return timeline;
}

const masterTimelineG1 =  gsap.timeline({
  paused: true,
});

masterTimelineG1.add(pageIn()).add(spinBadge(), "-=0.4");

export default masterTimelineG1;
