// Animation F
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .from("#f1__header-bar", 0.3, {
      y: -36,
    })
    .from(
      "#f1__title",
      0.3,
      {
        opacity: 0,
        y: "+=10",
      },
      "+=0.2"
    )
    .from(
      "#f1__search",
      0.3,
      {
        opacity: 0,
        y: "+=10",
      },
      "-=0.2"
    )
    .staggerFrom(
      ".f1__product-tile",
      1.6,
      {
        opacity: 0,
        y: "+=10",
        ease: "Expo.easeOut",
      },
      0.2
    )
    .to(".f1__product-tile", 0.3, {
      opacity: 0.3,
    });

  return timeline;
}

function cycleProducts() {
  const timeline =  gsap.timeline({
    onComplete: () => {
      timeline.progress(0).play();
    },
  });

  timeline
    .to("#f1__product1", 0.3, {
      opacity: 1,
    })
    .to("#f1__product1", 0.3, {
      opacity: 0.3,
      delay: 1.5,
    })
    .to(
      "#f1__product5",
      0.3,
      {
        opacity: 1,
      },
      "-=0.3"
    )
    .to("#f1__product5", 0.3, {
      opacity: 0.3,
      delay: 1.5,
    })
    .to(
      "#f1__product3",
      0.3,
      {
        opacity: 1,
      },
      "-=0.3"
    )
    .to("#f1__product3", 0.3, {
      opacity: 0.3,
      delay: 1.5,
    })
    .to(
      "#f1__product2",
      0.3,
      {
        opacity: 1,
      },
      "-=0.3"
    )
    .to("#f1__product2", 0.3, {
      opacity: 0.3,
      delay: 1.5,
    })
    .to(
      "#f1__product6",
      0.3,
      {
        opacity: 1,
      },
      "-=0.3"
    )
    .to("#f1__product6", 0.3, {
      opacity: 0.3,
      delay: 1.5,
    })
    .to(
      "#f1__product4",
      0.3,
      {
        opacity: 1,
      },
      "-=0.3"
    )
    .to("#f1__product4", 0.3, {
      opacity: 0.3,
      delay: 1.5,
    });

  return timeline;
}

const masterTimelineF1 =  gsap.timeline({
  paused: true,
});

masterTimelineF1.add(pageIn()).add(cycleProducts());

export default masterTimelineF1;
