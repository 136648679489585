// Animation D1
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .fromTo(
      "#d1",
      1,
      {
        opacity: 0,
        x: "-400px",
      },
      {
        opacity: 1,
        x: "0px",
      }
    )
    .from(
      "#d1__title",
      0.4,
      {
        opacity: 0,
      },
      "-=0.2"
    )
    .fromTo(
      "#d1__display-pic",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.2"
    )
    .fromTo(
      "#d1__pattern",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.3"
    );

  return timeline;
}

function productsIn() {
  const timeline =  gsap.timeline();

  timeline.staggerFrom(
    ".d1__tile",
    0.8,
    {
      y: "+=30px",
      opacity: 0,
    },
    0.1
  );

  return timeline;
}

function barsIn() {
  const timeline =  gsap.timeline({
    delay: "-=0.5",
  });

  timeline
    .staggerFromTo(
      "#d1 .bar",
      0.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
      },
      0.1
    )
    .fromTo(
      "#d1__social",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.3"
    );

  return timeline;
}

function tilesFadeOut() {
  const timeline =  gsap.timeline({
    delay: 1,
  });

  timeline.to(".d1__fade-helper", 0.2, {
    opacity: 0,
  });

  return timeline;
}

function zoomTile() {
  const timeline =  gsap.timeline();

  timeline
    .to("#d1__tile-one", 1.2, {
      scale: "1.6",
      x: "160px",
      y: "99px",
      ease: "Expo.easeOut",
    })
    .to(
      "#d1__tile-one__bg",
      0.2,
      {
        scaleX: 7,
        scaleY: 5,
        transformOrigin: "50% 50%",
      },
      "-=1.2"
    );

  return timeline;
}

function pageTwoIn() {
  const timeline =  gsap.timeline({
    delay: "-=1",
  });

  timeline
    .to("#d1__tile-one__bg", 0.3, {
      opacity: 0,
    })
    .from(
      "#d1__page-container",
      0.4,
      {
        opacity: 0,
      },
      "-=0.3"
    )
    .from("#d1__page-title", 0.3, {
      x: -10,
      opacity: 0,
    })
    .from("#d1__page-subtitle", 0.3, {
      x: -10,
      opacity: 0,
    });

  return timeline;
}

function clearStage() {
  const timeline =  gsap.timeline({
    delay: 3,
  });

  timeline.to("#d1", 0.4, {
    opacity: 0,
  });

  return timeline;
}

const masterTimelineD1 =  gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineD1.progress(0).play();
    }, 1000);
  },
});

masterTimelineD1
  .add(pageIn())
  .add(productsIn())
  .add(barsIn())
  .add(tilesFadeOut())
  .add(zoomTile())
  .add(pageTwoIn())
  .add(clearStage());

export default masterTimelineD1;
