import U from "./Utilities";

const THRESHOLD = 10;

class HeaderBar {
  constructor(el) {
    this.dom = {
      el,
      inner: el.querySelector(".header-bar__inner"),
      nav: el.querySelector(".nav"),
      header: document.querySelector(".header"),
      menuEl: document.getElementById("menu")
    };

    this.is = {
      invert:
        this.dom.header &&
        this.dom.header.querySelector(".header__background") &&
        window.innerWidth >= 1024,
      fixed: false,
      visible: true,
    };

    if (this.is.invert) {
      this.dom.el.classList.add("header-bar--invert");
    } else {
      this.dom.el.classList.add("header-bar--is-fixed");
      this.is.fixed = true;
    }

    this.ease = "Power3.easeInOut";
    this.lastScrollPos = U.scrollPos();

    U.autobind(this);
  }

  isMenuVisible() {
    return this.dom.menuEl.classList.contains("menu--is-visible");
  }

  ready() {
    if (this.is.fixed) {
      this.show();
    } else {
      this.dom.el.classList.add("header-bar--is-ready");
    }
  }

  show() {
    this.dom.el.classList.remove("header-bar--hide");
    this.dom.el.classList.add("header-bar--show");
    this.is.visible = true;
  }

  hide() {
    if (this.dom.el.classList.contains("header-bar--show")) {
      this.dom.el.classList.remove("header-bar--show");
      this.dom.el.classList.add("header-bar--hide");
      this.is.visible = false;
    } else if (!this.is.fixed) {
      this.dom.el.classList.remove("header-bar--is-ready");
    }
  }

  update(scrollPos) {
    if (!this.isMenuVisible() && scrollPos > this.dom.el.clientHeight) {
      this.dom.el.classList.add("header-bar--is-fixed");
      this.dom.el.classList.remove("header-bar--invert");
      this.is.fixed = true;

      if (
        (this.is.invert && scrollPos > this.dom.header.clientHeight) ||
        !this.is.invert
      ) {
        this.show();
      } else {
        this.dom.el.classList.add("header-bar--invert");
        this.hide();
      }
    } else {
      this.dom.el.classList.remove("header-bar--hide");
      this.is.fixed = false;

      if (this.is.invert) {
        this.dom.el.classList.add("header-bar--invert");
        this.dom.el.classList.remove("header-bar--is-fixed");
        this.is.fixed = false;
      }
    }

    this.lastScrollPos = scrollPos;
  }
}

export default HeaderBar;
