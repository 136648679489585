import a from "../animations/a";
import b from "../animations/b";
import c from "../animations/c";
import d from "../animations/d";
import e from "../animations/e";
import f from "../animations/f";
import g from "../animations/g";
import h from "../animations/h";
import i from "../animations/i";
import j from "../animations/j";
import k from "../animations/k";
import m from "../animations/m";
import n from "../animations/n";
import o from "../animations/o";
import p from "../animations/p";
import q from "../animations/q";
import r from "../animations/r";
import s from "../animations/s";
import t from "../animations/t";
import u from "../animations/u";

const Animations = { a, b, c, d, e, f, g, h, i, j, k, m, n, o, p, q, r, s, t, u };

export default Animations;
