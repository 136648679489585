// Animation I
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .from("#I1__header-bar", 0.3, {
      y: -36,
    })
    .staggerFrom(
      ".I1__tile",
      0.4,
      {
        opacity: 0,
        y: "+=10",
      },
      0.1
    )
    .staggerFrom(
      ".I1__graph",
      0.5,
      {
        scaleY: 0,
        transformOrigin: "50% 100%",
      },
      0.1
    );

  return timeline;
}

function pieIn() {
  const timeline =  gsap.timeline();

  timeline.from("#I1__pie", 0.8, {
    opacity: 0,
    rotation: -50,
    transformOrigin: "50% 50%",
  });

  return timeline;
}

const masterTimelineI1 =  gsap.timeline({
  paused: true,
});

masterTimelineI1.add(pageIn()).add(pieIn());

export default masterTimelineI1;
