import U from "./Utilities";

class ScrollEvents {
  constructor() {
    this.events = [];
    this.complete = [];
    this.loop = false;

    U.autobind(this);
  }

  start() {
    window.addEventListener("scroll", () => {
      this.update();
    });

    this.update();
  }

  stop() {
    window.removeEventListener("scroll", () => {
      this.update();
    });
  }

  add(callback) {
    if (U.is.fn(callback)) {
      this.events.push({
        callback,
      });
    } else {
      console.warn("ScrollEvents: callback must be a function.");
    }

    return this;
  }

  remove(event) {
    const index = this.events.indexOf(event);

    if (index !== -1) {
      this.events.splice(index, 1);
    }
  }

  trigger(el, triggerPos, callback) {
    this.events.push({
      el,
      trigger: ScrollEvents.parseTriggerPos(triggerPos),
      callback,
    });

    return this;
  }

  update() {
    if (!this.loop && this.events.length) {
      this.loop = true;

      U.raf(() => {
        this.rafCb();
      });
    }
  }

  dispatch(event) {
    const scrollTop = U.scrollPos();

    if ("trigger" in event) {
      if (ScrollEvents.checkTrigger(event, scrollTop)) {
        event.callback(scrollTop);
        this.complete.push(event);
      }
    } else if (event.callback(scrollTop)) {
      this.complete.push(event);
    }
  }

  rafCb() {
    if (this.events.length) {
      this.events.forEach(event => {
        this.dispatch(event);
      });

      this.complete.forEach(event => {
        this.remove(event);
      });

      this.complete = [];
    }

    this.loop = false;
  }

  static checkTrigger(event) {
    return event.el.getBoundingClientRect().top < event.trigger();
  }

  static parseTriggerPos(str) {
    if (typeof str === "string" && str.length) {
      const values = [];

      str = str.replace(/\s/g, "");
      const matches = str.match(/[-+]?\d+(?:%|vh|px)/g);

      if (matches) {
        matches.forEach(match => {
          values.push(ScrollEvents.parseValue(match));
        });
      } else {
        console.warn("ScrollEvents: triggerPos is not a valid expression");
      }

      return values.length
        ? () => {
            let offset = 0;

            values.forEach(value => {
              offset += typeof value === "function" ? value() : value;
            });

            return offset;
          }
        : null;
    }

    return [str];
  }

  static parseValue(str) {
    const matches = str.match(new RegExp(/([-+]?)(\d+)(%|vh|px)/));
    let value = parseInt(matches[2], 10);

    if (matches[1] === "-") {
      value *= -1;
    }

    if (matches[3] !== "px") {
      const calc = () => {
        return Math.round((value / 100) * window.innerHeight);
      };

      return calc;
    }

    return value;
  }
}

export default ScrollEvents;
