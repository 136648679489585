/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'app' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

/* global SplitText */
/* jshint unused:false */
/* global HTMLElement */
/* global SVGElement */
/* global gsap */

import "element-remove";
import "nodelist-foreach-polyfill";
import "intersection-observer";
import "requestidlecallback-polyfill";
import "packs/polyfill";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

import Controller from "modules/Controller";
import FormHandler from "modules/FormHandler";
import PaginationHandler from "modules/PaginationHandler";

(function() {
  document.addEventListener("DOMContentLoaded", () => {
    const controller = new Controller();
    controller.init();

    const formHandler = new FormHandler();
    const paginationHandler = new PaginationHandler();

    // Grid + Layout
    const grid = document.createElement("div");
    grid.id = "grid-overlay";

    document.addEventListener("keydown", e => {
      switch (e.keyCode) {
        case 71: {
          if ((e.metaKey || e.ctrlKey) && !e.altKey && !e.shiftKey) {
            e.preventDefault();

            if (document.getElementById("grid-overlay")) {
              grid.remove();
            } else {
              document.body.appendChild(grid);
            }
          }

          break;
        }
        default: {
          break;
        }
      }
    });

    window.addEventListener('pageshow', () => {
      controller.showContent();
    });
  });
})();
