import { gsap } from "gsap";

const leadTrackerSvg = document.getElementById('lead-tracker-anim');
const domElements = {};

function initData() {
  domElements.svg = leadTrackerSvg;
  domElements.nav = leadTrackerSvg.querySelector('.lead-tracker-anim__nav');
  domElements.pageTitle = leadTrackerSvg.querySelector('.lead-tracker-anim__page-title');
  domElements.cards = leadTrackerSvg.querySelectorAll('.lead-tracker-anim__card');
  domElements.columns = leadTrackerSvg.querySelectorAll('.lead-tracker-anim__column');
  domElements.dragCard = leadTrackerSvg.querySelector('.lead-tracker-anim__drag-card');
  domElements.cursor = leadTrackerSvg.querySelector('.lead-tracker-anim__cursor');
  domElements.deeplink = leadTrackerSvg.querySelector('#lead-deeplink');
  domElements.bg1 = domElements.columns[0].querySelector('.lead-tracker-anim__column-bg');
  domElements.bg2 = domElements.columns[1].querySelector('.lead-tracker-anim__column-bg');
}

function showPage() {
  const tl =  gsap.timeline();

  tl.from(domElements.nav, 0.4, {
    y: -36,
    ease: 'Power1.easeInOut'
  })
    .from(domElements.pageTitle, 0.3, {
      opacity: 0,
      y: '+=5'
    }, 0.2);

  return tl;
}

function showColumns() {
  const tl =  gsap.timeline();

  domElements.bg1.setAttribute('d', domElements.bg1.getAttribute('data-a'));
  domElements.bg2.setAttribute('d', domElements.bg2.getAttribute('data-a'));

  tl
    .staggerFrom(domElements.svg.querySelectorAll('.lead-tracker-anim__column-heading'), 0.4, {
      opacity: 0,
      delay: 0.3
    }, 0.05)
    .staggerFrom(domElements.svg.querySelectorAll('.lead-tracker-anim__column-bg'), 0, {
      scaleY: 0,
      cycle: {
        duration(i) {
          return domElements.columns[i].querySelectorAll('.lead-tracker-anim__card').length / 5
        }
      },
      ease: 'Power1.easeInOut'
    }, 0.05, '-=0.5')
    .staggerFrom(domElements.cards, 0.4, {
      y: '+=5',
      opacity: 0,
      cycle: {
        delay(i, target) {
          const col = target.parentNode;
          const colCards = col.querySelectorAll('.lead-tracker-anim__card');
          const colIndex = Array.prototype.indexOf.call(domElements.columns, col);
          const cardIndex = Array.prototype.indexOf.call(colCards, target);

          return i === domElements.cards.length - 1 ? 0.15 : colIndex * 0.05 + cardIndex * 0.2;
        }
      }
    }, 0, '-=1');

  return tl;
}

function reorderCards() {
  const tl =  gsap.timeline();
  const col1cards = domElements.columns[0].querySelectorAll('.lead-tracker-anim__card');
  const fadeColumns = Array.prototype.filter.call(domElements.columns, function (el, i) {
    return i !== 1
  });

  tl.add('adjustHeights', 0.8)
    .to(fadeColumns, 0.3, {
      opacity: 0.5,
      delay: 0.5
    })
    .to(domElements.dragCard, 1, {
      x: '+=96',
      y: '+=32',
      ease: 'Power1.easeInOut'
    }, '-=0.2')
    .to(fadeColumns, 0.4, {
      opacity: 1
    }, '-=-0.1')
    .to(col1cards[col1cards.length - 1], 0.3, {
      y: '-=32'
    }, 'adjustHeights')
    .to(domElements.bg1, 0.3, {
      morphSVG: domElements.bg1.getAttribute('data-b')
    }, 'adjustHeights')
    .to(domElements.bg2, 0.3, {
      morphSVG: domElements.bg2.getAttribute('data-b')
    }, 'adjustHeights');

  return tl;
}

function clickCard() {
  const tl =  gsap.timeline();

  tl.from(domElements.cursor.children, 0.15, {
    scale: 0,
    transformOrigin: '50% 50%',
    delay: 0.3
  })
    .to(domElements.cursor.children, 0.15, {
      opacity: 0,
      delay: 0.2
    })
    .to(domElements.nav, 0.4, {
      y: -36,
      ease: 'Power1.easeInOut'
    }, 0.5)
    .staggerTo([domElements.pageTitle, domElements.columns], 0.25, {
      opacity: 0
    }, 0.3, 0.5);

  return tl;
}

function showDeeplink() {
  const tl =  gsap.timeline();

  tl.to(domElements.dragCard, 0.5, {
    opacity: 0
  })
    .from(domElements.deeplink, 0.5, {
      y: '+=15',
      opacity: 0,
      delay: 0.1
    });

  return tl;
}

function scrollDeeplink() {
  const tl =  gsap.timeline();

  tl.to(domElements.svg.querySelector('.lead-tracker-anim__scroll').children, 0.8, {
    y: '-=68',
    delay: 0.5,
    ease: 'Power1.easeInOut'
  })
    .to(domElements.deeplink, 0.4, {
      opacity: 0,
      delay: 1.5
    });

  return tl;
}

const master =  gsap.timeline({
  paused: true,
  onComplete() {
    window.setTimeout(function () {
      master.progress(0).play();
    }, 750)
  }
});

if (leadTrackerSvg) {
  initData();
  master
    .add(showPage())
    .add(showColumns(), '-=0.3')
    .add(reorderCards())
    .add(clickCard())
    .add(showDeeplink())
    .add(scrollDeeplink());
}

export default master;
