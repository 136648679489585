import SplitText from "../vendor/SplitText";
import { gsap } from "gsap";

class StoryItem {
  constructor(el) {
    this.el = el;
    this.timeline = gsap.timeline({ paused: true });

    this.chars = StoryItem.getChars(this.el.querySelector(".story-item__cta"));

    this.timeline.staggerFromTo(
      this.chars,
      0.35,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "Back.easeOut",
      },
      0.015
    );

    this.el.addEventListener("mouseenter", () => {
      this.timeline.play();
    });
    this.el.addEventListener("mouseleave", () => {
      this.timeline.reverse();
    });
  }

  static getChars(el) {
    const split = new SplitText(el, {
      type: "chars",
      charsClass: "word-reveal__char",
    });

    split.chars.forEach(char => {
      char.removeAttribute("style");
    });

    return split.chars;
  }
}

export default StoryItem;
