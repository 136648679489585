class PaginationHandler {
  constructor() {
    this.dom = {
      loadMoreButton: document.querySelector(".load-more__button"),
      content: document.querySelector(".load-more__content"),
    };
    this.bindLoadMoreButton();
  }

  toggleLoadMoreDisabled() {
    const button = this.dom.loadMoreButton;
    if (button.hasAttribute("disabled")) {
      button.disabled = true;
    } else {
      button.removeAttribute("disabled");
    }
  }

  bindLoadMoreButton() {
    if (this.dom.loadMoreButton == null) {
      return;
    }
    this.dom.loadMoreButton.addEventListener(
      "click",
      this.onClick.bind(this),
      false
    );
  }

  onClick(event) {
    this.toggleLoadMoreDisabled();
    this.performRequest();
    event.preventDefault();
  }

  performRequest() {
    const path = this.dom.loadMoreButton.getAttribute("data-path");
    const page = this.dom.loadMoreButton.getAttribute("data-page");
    const url = `${path}?page=${page}`;

    const httpRequest = new XMLHttpRequest();
    const handleRequest = () => {
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        this.toggleLoadMoreDisabled();
        if (httpRequest.status === 200) {
          const response = JSON.parse(httpRequest.response);
          this.appendToContent(response.content);
          this.incrementPage(page);
          if (!response.has_next_page) {
            this.noMoreResults();
          }
        }
      }
    };
    httpRequest.onreadystatechange = handleRequest;
    httpRequest.open("get", url, true);
    httpRequest.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    httpRequest.send();
  }

  appendToContent(content) {
    this.dom.content.insertAdjacentHTML("beforeend", content);
  }

  incrementPage(page) {
    this.dom.loadMoreButton.setAttribute("data-page", parseInt(page) + 1);
  }

  noMoreResults() {
    this.dom.loadMoreButton.style.visibility = "hidden";
  }
}

export default PaginationHandler;
