import U from "./Utilities";
import { gsap } from "gsap";

class Expandable {
  constructor(
    el,
    options = {
      ease: "Power3.easeInOut",
      duration: 0.5,
      onOpen: null,
      onClose: null,
    }
  ) {
    this.dom = {
      el,
      wrapper: document.createElement("div"),
      content: el.children,
    };

    this.timeline =  gsap.timeline();
    this.ease = options.ease;
    this.duration = options.duration;

    this.isOpen = false;
    this.onOpen = options.onOpen;
    this.onClose = options.onClose;

    this.dom.wrapper.style.height = 0;
    this.dom.wrapper.style.overflow = "hidden";
    U.wrap(this.dom.el, this.dom.wrapper);

    return U.autobind(this);
  }

  toggle(callback = () => {}) {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }

    callback();
  }

  open() {
    if (!this.isOpen) {
      this.timeline.fromTo(
        this.dom.wrapper,
        this.duration,
        {
          height: 0,
        },
        {
          height: U.rect(this.dom.el).height,
          ease: this.ease,
        }
      );

      this.isOpen = true;
    }
  }

  close() {
    if (this.isOpen) {
      this.timeline.fromTo(
        this.dom.wrapper,
        this.duration,
        {
          height: U.rect(this.dom.el).height,
        },
        {
          height: 0,
          ease: this.ease,
        }
      );

      this.isOpen = false;
    }
  }
}

export default Expandable;
