import U from "./Utilities";
import { gsap } from "gsap";

export default function ScrollToButtons() {
  const buttons = document.querySelectorAll(".scroll-to-button");
  buttons.forEach(button => {
    const target = document.getElementById(button.getAttribute("data-target"));

    if (target) {
      button.addEventListener("click", e => {
        scrollTo(target);
      });
    }
  });

  function scrollTo(target) {
    const scrollPos = U.scrollPos();
    const maxScroll = document.body - window.outerHeight;
    let targetPos = U.rect(target).top + scrollPos - 48;

    if (targetPos < scrollPos) {
      targetPos -= U.rect(this.dom.headerBar).height;
    }

    const duration = Math.min(Math.abs(targetPos - scrollPos) / 1000, 1.5);

    gsap.to(window, duration, {
      scrollTo: targetPos > maxScroll ? maxScroll : targetPos,
      ease: "Power2.easeInOut",
    });
  }
}
