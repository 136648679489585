class FormHandler {
  constructor() {
    this.bindSubmit();
  }

  toggleSubmitDisabled(formEl) {
    if (formEl.hasAttribute("data-disable-on-submit")) {
      const button = formEl.querySelector("input[type='submit']");
      if (button.hasAttribute("disabled")) {
        button.disabled = true;
      } else {
        button.removeAttribute("disabled");
      }
    }
  }

  bindSubmit() {
    document.querySelectorAll("form").forEach(formEl => {
      formEl.addEventListener("submit", this.onFormSubmit.bind(this), false);
    });
  }

  onFormSubmit(event) {
    event.preventDefault();
    const formEl = event.target;
    this.toggleSubmitDisabled(formEl);
    var formHandler = this
    grecaptcha.execute(RECAPTCHA_SITE_KEY, {action: 'contact'}).then(function(token) {
      document.getElementById(RECAPTCHA_SITE_KEY).value = token
      formHandler.performRequest(formEl);
    });

  }

  performRequest(formEl) {
    const formData = new FormData(formEl);
    const action = formEl.getAttribute("action");
    const method = formEl.getAttribute("method") || "post";
    const httpRequest = new XMLHttpRequest();
    const handleRequest = () => {
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        this.toggleSubmitDisabled(formEl);
        if (httpRequest.status === 200) {
          this.onSubmitSuccess(formEl);
        } else if (httpRequest.status === 401) {
          this.onRecaptchaFailure(formEl);
        } else {
          this.onSubmitFailure(formEl);
        }
      }
    };
    httpRequest.onreadystatechange = handleRequest;
    httpRequest.open(method, action, true);
    httpRequest.send(formData);
  }

  onSubmitSuccess(formEl) {
    this.showSuccessMessage(formEl);
  }

  onSubmitFailure(formEl) {
    this.showErrorMessage(formEl);
  }

  onRecaptchaFailure(formEl) {
    this.showRecaptchaMessage(formEl);
  }

  showErrorMessage(formEl) {
    formEl.querySelector(".failure-message").classList.add("message-visible");
    formEl
      .querySelector(".success-message")
      .classList.remove("message-visible");
    formEl
      .querySelector(".recaptcha-message")
      .classList.remove("message-visible");
  }


  showRecaptchaMessage(formEl) {
    formEl.querySelector(".recaptcha-message").classList.add("message-visible");
    formEl
      .querySelector(".success-message")
      .classList.remove("message-visible");
    formEl
      .querySelector(".failure-message")
      .classList.remove("message-visible");
    formEl
      .querySelector(".submit-button")
      .classList.add("button-hidden");
  }

  showSuccessMessage(formEl) {
    formEl.querySelector(".success-message").classList.add("message-visible");
    formEl
      .querySelector(".failure-message")
      .classList.remove("message-visible");
    formEl
      .querySelector(".recaptcha-message")
      .classList.remove("message-visible");
    formEl
      .querySelector(".submit-button")
      .classList.add("button-hidden");
  }
}

export default FormHandler;
