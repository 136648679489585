import Animations from "./Animations";
import SplitText from "../vendor/SplitText";
import { gsap } from "gsap";

class BlockAnimation {
  constructor(el) {
    this.dom = {
      el,
      bg: el.querySelector(".layout-block__background"),
      title: el.querySelector(".content-block__title"),
      badge: el.querySelector(".badge"),
      heading: el.querySelector(".word-reveal"),
      text: el.querySelectorAll(
        "p, .content-block__subheading, a, .content-block__button"
      ),
      img: el.querySelector(".image-frame"),
      animations: el.querySelectorAll(".animation"),
    };

    if (this.dom.animations.length) {
      this.dom.animations[0].classList.add("animation--is-visible");

      if (this.dom.animations.length > 1) {
        this.dom.toggles = el.querySelectorAll(".animation__toggle");
        this.activeIndex = 0;

        this.dom.toggles.forEach((toggle, j) => {
          if (j === this.activeIndex) {
            toggle.classList.add("animation__toggle--is-active");
          }

          toggle.addEventListener("click", e => {
            if (j !== this.activeIndex) {
              e.preventDefault();

              this.dom.toggles[this.activeIndex].classList.remove(
                "animation__toggle--is-active"
              );
              toggle.classList.add("animation__toggle--is-active");

              gsap.to(this.dom.animations[this.activeIndex], 0.5, {
                opacity: 0,
                onComplete: () => {
                  this.dom.animations[this.activeIndex].classList.remove(
                    "animation--is-visible"
                  );
                  Animations[
                    this.dom.animations[this.activeIndex].getAttribute(
                      "data-anim"
                    )
                  ]
                    .pause()
                    .progress(0);
                  this.dom.animations[j].classList.add("animation--is-visible");

                  gsap.fromTo(
                    this.dom.animations[j],
                    0.5,
                    {
                      opacity: 0,
                    },
                    {
                      opacity: 1,
                      onComplete: () => {
                        Animations[
                          this.dom.animations[j].getAttribute("data-anim")
                        ].play();

                        this.activeIndex = j;
                      },
                    }
                  );
                },
              });
            }
          });
        });
      }
    }

    this.nodelay = el.classList.contains("layout-block--nodelay");
    this.alt = !el.classList.contains("layout-block--reversed");

    this.t = 1.5;
    this.delay = this.nodelay ? 0.1 : 0.4;
    this.durations = {
      bg: this.t,
      heading: this.t * 2,
      text: this.t * 2,
      img: this.t * 0.75,
    };

    this.timeline =  gsap.timeline({
      paused: true,
    });

    this.timeline
      .add("start", 0)
      .add("left", this.delay)
      .add("right", this.delay);

    if (this.dom.bg) {
      this.timeline.fromTo(
        this.dom.bg,
        this.durations.bg,
        {
          scaleY: 0,
        },
        {
          scaleY: 1,
          ease: "Power3.easeInOut",
        },
        "start"
      );
    }

    if (this.dom.title) {
      this.timeline.fromTo(
        this.dom.title,
        this.durations.text,
        { opacity: 0 },
        { opacity: 1, ease: "Expo.easeOut" },
        this.alt ? `right+=${this.delay}` : `left+=${this.delay}`
      );
    }

    this.timeline
      .fromTo(
        this.dom.heading,
        this.durations.heading,
        { opacity: 0 },
        { opacity: 1, ease: "Expo.easeOut" },
        this.alt ? `right+=${this.delay}` : `left+=${this.delay}`
      )
      .fromTo(
        this.dom.text,
        this.durations.text,
        { opacity: 0 },
        { opacity: 1, ease: "Expo.easeOut" },
        this.alt ? `right+=${this.delay}` : `left+=${this.delay}`
      )
      .fromTo(
        this.dom.img,
        this.durations.img,
        { opacity: 0 },
        {
          y: 0,
          opacity: 1,
          ease: "Expo.easeOut",
          onStart: () => {
            if (this.dom.img.classList.contains("image-frame--cards")) {
              window.setTimeout(() => {
                this.dom.img.classList.add("image-frame--is-spread");
              }, this.durations.img * 300);
            }
          },
        },
        this.alt ? "left" : "right"
      )
      .addLabel("childEntry", 0.25)
      .call(
        () => {
          if (this.dom.animations.length) {
            this.timeline.childAnim =
              Animations[this.dom.animations[0].getAttribute("data-anim")];
            this.timeline.childAnim.play();
          }
        },
        [],
        this,
        "childEntry"
      );

    if (this.dom.badge) {
      this.timeline.fromTo(
        this.dom.badge,
        0.25,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: "Power1.easeOut",
        },
        this.alt ? `right+=${this.delay}` : `left+=${this.delay}`
      );
    }

    return this.timeline;
  }

  static splitWords(el) {
    const out = [];

    const split = new SplitText(el, {
      type: "words",
      wordsClass: "word-reveal__mask",
    });

    split.words.forEach(word => {
      word.removeAttribute("style");
      word.innerHTML = `<span class="word-reveal__word">${
        word.textContent
      }</span>`;

      word = word.children[0];

      const matches = word.textContent.match(/RangeMe|-/);

      if (matches) {
        switch (matches[0]) {
          case "RangeMe": {
            word.textContent = "Range*Me";

            break;
          }
          case "-": {
            word.textContent = word.textContent.replace("-", "*-*");

            break;
          }
          default: {
            break;
          }
        }

        const subsplit = new SplitText(word, {
          type: "words",
          wordsClass: "word-reveal__word",
          wordDelimiter: "*",
        });

        subsplit.words.forEach(subword => {
          word.parentElement.appendChild(subword);
          out.push(subword);
        });

        word.parentElement.firstElementChild.remove();
      } else {
        out.push(word);
      }
    });

    return out;
  }
}

export default BlockAnimation;
