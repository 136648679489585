// Animation B1
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .fromTo(
      "#b1",
      1,
      {
        opacity: 0,
        x: "-400px",
      },
      {
        opacity: 1,
        x: "0px",
      }
    )
    .from(
      "#b1__title",
      0.4,
      {
        opacity: 0,
      },
      "-=0.2"
    )
    .fromTo(
      "#b1__display-pic",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.2"
    )
    .fromTo(
      "#b1__pattern",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.3"
    );

  return timeline;
}

function productsIn() {
  const timeline =  gsap.timeline();

  timeline.staggerFrom(
    ".b1__tile",
    0.8,
    {
      y: "+=30px",
      opacity: 0,
    },
    0.1
  );

  return timeline;
}

function barsIn() {
  const timeline =  gsap.timeline({
    delay: "-=0.5",
  });

  timeline
    .staggerFromTo(
      "#b1 .bar",
      0.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
      },
      0.1
    )
    .fromTo(
      "#b1__social",
      0.4,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      },
      "-=0.3"
    );

  return timeline;
}

function tilesFadeOut() {
  const timeline =  gsap.timeline({
    delay: 1,
  });

  timeline.to(".b1__fade-helper", 0.2, {
    opacity: 0,
  });

  return timeline;
}

function zoomTile() {
  const timeline =  gsap.timeline();

  timeline.to("#b1__tile-one", 1.2, {
    scale: "2.5",
    x: "198px",
    y: "72px",
    ease: "Back.easeOut",
  });

  return timeline;
}

function headIn() {
  const timeline =  gsap.timeline();

  timeline
    .from("#b1__head__display-pic", 0.4, {
      opacity: 0,
    })
    .from(".b1__head__bars", 0.4, {
      scaleX: 0,
    });

  return timeline;
}

function controlsIn() {
  const timeline =  gsap.timeline();

  timeline.from("#b1__tile-one__controls", 0.4, {
    opacity: 0,
  });

  return timeline;
}

function pulse() {
  const timeline =  gsap.timeline({
    delay: 1,
  });

  timeline
    .fromTo(
      "#b1__tile-one__controls__pulse > #top-layer",
      0.5,
      {
        scale: 0.8,
        opacity: 0,
        transformOrigin: "50% 50%",
      },
      {
        scale: 1.6,
        opacity: 1,
      }
    )
    .to("#b1__tile-one__controls__pulse > #top-layer", 0.4, {
      scale: 0.9,
      opacity: 0,
    })
    .fromTo(
      "#b1__tile-one__controls__pulse > #bottom-layer",
      0.3,
      {
        scale: 0.9,
        opacity: 0,
        transformOrigin: "50% 50%",
      },
      {
        scale: 1.5,
        opacity: 1,
      },
      "-=0.8"
    )
    .to(
      "#b1__tile-one__controls__pulse > #bottom-layer",
      0.2,
      {
        scale: 0.9,
        opacity: 0,
      },
      "-=0.2"
    );

  return timeline;
}

function requested() {
  const timeline =  gsap.timeline();

  timeline
    .to(
      "#b1__tile-one__controls, #b1__tile-one__image, #b1__tile-one__head",
      0.4,
      {
        opacity: 0,
      }
    )
    .from("#b1__tile-one__requested, #b1__tile-one__tick", 0.4, {
      opacity: 0,
    });

  return timeline;
}

function clearStage() {
  const timeline =  gsap.timeline({
    delay: 3,
  });

  timeline.to("#b1", 0.4, {
    opacity: 0,
  });

  return timeline;
}

const masterTimelineB1 =  gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineB1.progress(0).play();
    }, 1000);
  },
});

masterTimelineB1
  .add(pageIn())
  .add(productsIn())
  .add(barsIn())
  .add(tilesFadeOut())
  .add(zoomTile())
  .add(headIn())
  .add(controlsIn())
  .add(pulse())
  .add(requested())
  .add(clearStage());

export default masterTimelineB1;
