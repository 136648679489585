import { gsap } from "gsap";
import Plyr from "plyr";

import U from "./Utilities";

class Video {
  constructor(el) {
    U.autobind(this);

    this.dom = {
      el,
      overlay: el.querySelector(".video-modal__overlay"),
      inner: el.querySelector(".video-modal__inner"),
      show: document.getElementById("show-video"),
      hide: el.querySelector(".video-modal__close"),
      loop: document.getElementById("video-loop"),
    };

    this.blur = {
      val: 0,
    };

    this.dom.el.remove();
    this.dom.el.classList.remove("video-modal--is-hidden");

    this.plyr = new Plyr(el.querySelector("video"), {
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "fullscreen",
      ],
      iconUrl: "/public_site_assets/plyr-fcb74e14da4c73db94d641ddf9f4ad7714d70bb1f87dd6ba10c15321639193b3.svg",
      invertTime: false,
      toggleInvert: false,
      tooltips: {
        controls: false,
        seek: false,
      },
    });

    this.plyr.elements.container.classList.add("video-modal__wrapper");
    this.dom.wrapper = this.plyr.elements.container;

    this.dom.show.addEventListener("mouseenter", () => {
      gsap.to(this.dom.loop, 0.25, {
        opacity: 0.5,
        ease: "Power1.easeOut",
      });
    });

    this.dom.show.addEventListener("mouseleave", () => {
      gsap.to(this.dom.loop, 0.25, {
        opacity: 0.25,
        ease: "Power1.easeOut",
      });
    });

    this.dom.show.addEventListener("click", this.show);
    this.dom.hide.addEventListener("click", this.hide);
  }

  show() {
    this.dom.loop.pause();

    gsap.to(this.blur, 0.25, {
      val: 5,
      onUpdate: this.applyBlur,
    });

    const timeline = gsap.timeline({
      paused: true,
    });

    timeline
      .fromTo(
        this.dom.overlay,
        0.25,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: "Power1.easeOut",
        }
      )
      .fromTo(
        this.dom.wrapper,
        1,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          ease: "Expo.easeOut",
          onComplete: () => {
            this.plyr.play();
          },
        },
        "-=0.1"
      )
      .fromTo(
        this.dom.hide,
        0.25,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: "Power1.easeOut",
        },
        "-=0.75"
      );

    document.body.appendChild(this.dom.el);
    document.addEventListener("keydown", this.keydownHandler);
    this.dom.el.addEventListener("click", this.clickHandler);

    timeline.play();
  }

  hide() {
    const timeline = gsap.timeline();

    timeline.fromTo(
      [this.dom.overlay, this.dom.wrapper, this.dom.hide],
      0.25,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: "Power1.easeOut",
        onComplete: () => {
          this.dom.el.remove();
          this.dom.loop.play();

          gsap.to(this.blur, 0.25, {
            val: 0,
            onUpdate: this.applyBlur,
          });
        },
      }
    );

    document.removeEventListener("keydown", this.keydownHandler);
    this.dom.el.removeEventListener("click", this.clickHandler);
  }

  applyBlur() {
    gsap.set(this.dom.loop, {
      filter: `blur(${this.blur.val}px)`,
    });
  }

  keydownHandler(e) {
    if (e.keyCode === 27) {
      this.hide();
    }
  }

  clickHandler(e) {
    const target = e.target;

    if (target === this.dom.overlay || target === this.dom.inner) {
      this.hide();
    }
  }
}

export default Video;
