// Animation P
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

function headerSlideDown() {
  const timeline =  gsap.timeline();

  timeline
    .from(
      "#p1__header-bar",
      0.5,
      {
        y: "-=36",
      },
      "-=0.4"
    )
    .staggerFrom(
      "#p1__page-1__panel-5, #p1__page-1__panel-4, #p1__page-1__panel-3, #p1__page-1__panel-2, #p1__page-1__panel-1, #p1__page-1__title",
      1,
      {
        opacity: 0,
      },
      -0.1
    );

  return timeline;
}

function pageOneFadeOut() {
  const timeline =  gsap.timeline();

  timeline.to(
    ".p1__fade-helper",
    0.5,
    {
      opacity: 0,
    },
    "+=0.5"
  );

  return timeline;
}

function morphDp() {
  const timeline =  gsap.timeline();

  timeline.to("#p1__page-1__panel-1", 0.5, {
    x: 37,
    y: 49,
  });

  return timeline;
}

function pageTwoContentFadeIn() {
  const timeline =  gsap.timeline();

  timeline.from("#p1__page-2__content", 0.5, {
    opacity: 0,
  });

  return timeline;
}

function imageReveal() {
  const timeline =  gsap.timeline();

  timeline
    .staggerFrom(
      "#p1__page-2__cta, #p1__page-2__text",
      0.5,
      {
        opacity: 0,
      },
      0.1
    )
    .to(
      "#p1__page-2__img-1--mask",
      0.5,
      {
        scaleX: 0,
        transformOrigin: "100% 50%",
      },
      "-=0.35"
    )
    .to(
      "#p1__page-2__img-2--mask",
      0.5,
      {
        scaleY: 0,
        transformOrigin: "50% 100%",
      },
      "-=0.35"
    )
    .to(
      "#p1__page-2__img-3--mask",
      0.5,
      {
        scaleX: 0,
      },
      "-=0.35"
    );

  return timeline;
}

function clearStage() {
  const timeline =  gsap.timeline({
    delay: 3,
  });

  timeline.to("#p1__container", 0.5, {
    opacity: 0,
  });

  return timeline;
}

const masterTimelineP1 =  gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineP1.restart();
    }, 1000);
  },
});

masterTimelineP1
  .add(headerSlideDown())
  .add(pageOneFadeOut())
  .add(morphDp())
  .add(pageTwoContentFadeIn(), "-=0.3")
  .add(imageReveal())
  .add(clearStage());

export default masterTimelineP1;
