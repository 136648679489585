// Animation N
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .from("#n1__header-bar", 0.3, {
      y: -36,
    })
    .from(
      "#n1__title",
      0.3,
      {
        opacity: 0,
        y: "+=10",
      },
      "+=0.2"
    )
    .from(
      "#n1__search",
      0.3,
      {
        opacity: 0,
        y: "+=10",
      },
      "-=0.2"
    )
    .staggerFrom(
      ".n1__product-tile",
      1.6,
      {
        opacity: 0,
        y: "+=10",
        ease: "Expo.easeOut",
      },
      0.2
    );

  return timeline;
}

function tilesOut() {
  const timeline =  gsap.timeline();

  timeline
    .to(".n1__product-tile-secondary", 0.3, {
      opacity: 0,
    })
    .to(
      "#n1__header-bar, #n1__title, #n1__search",
      0.3,
      {
        opacity: 0,
      },
      "-=0.2"
    );

  return timeline;
}

function mainTileIn() {
  const timeline =  gsap.timeline();

  timeline
    .to("#n1__product-1", 1.5, {
      scale: 1.83333333,
      transformOrigin: "top left",
      x: 118,
      y: 18,
      ease: "Expo.easeOut",
    })
    .to(
      "#n1__product-1 #bg",
      0.5,
      {
        attr: {
          rx: 1,
        },
      },
      "-=1.5"
    )
    .to(
      "#n1__product-1__logo",
      0.3,
      {
        fill: "#0076FF",
      },
      "-=0.6"
    )
    .to(
      "#n1__product-1__actions-sm",
      0.9,
      {
        opacity: 0,
      },
      "-=1.5"
    )
    .from(
      "#n1__product-1__actions-lg",
      0.5,
      {
        opacity: 0,
      },
      "-=0.2"
    );

  return timeline;
}

function pulse() {
  const timeline =  gsap.timeline({
    delay: 1,
  });

  timeline
    .fromTo(
      "#n1__tile-one__controls__pulse > #n1__top-layer",
      0.5,
      {
        scale: 0.8,
        opacity: 0,
        transformOrigin: "50% 50%",
      },
      {
        scale: 1.6,
        opacity: 1,
      }
    )
    .to("#n1__tile-one__controls__pulse > #n1__top-layer", 0.4, {
      scale: 0.9,
      opacity: 0,
    })
    .fromTo(
      "#n1__tile-one__controls__pulse > #n1__bottom-layer",
      0.3,
      {
        scale: 0.9,
        opacity: 0,
        transformOrigin: "50% 50%",
      },
      {
        scale: 1.5,
        opacity: 1,
      },
      "-=0.8"
    )
    .to(
      "#n1__tile-one__controls__pulse > #n1__bottom-layer",
      0.2,
      {
        scale: 0.9,
        opacity: 0,
      },
      "-=0.2"
    );

  return timeline;
}

function requested() {
  const timeline =  gsap.timeline();

  timeline
    .to(
      "#n1__product-1__actions-lg, #n1__product-1__img, #n1__product-1__head",
      0.4,
      {
        opacity: 0,
      }
    )
    .from("#n1__caption-requested, #n1__tick", 0.4, {
      opacity: 0,
    });

  return timeline;
}

function clearStage() {
  const timeline =  gsap.timeline({
    delay: 3,
  });

  timeline
    .to("#n1__product-1 #bg, #n1__caption-requested, #n1__tick", 0.5, {
      opacity: 0,
      y: "-=30",
    })
    .to("#n1__bg", 0.8, {
      scaleY: 0,
      transformOrigin: "50% 100%",
    });

  return timeline;
}

const masterTimelineN1 =  gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineN1.progress(0).play();
    }, 1000);
  },
});

masterTimelineN1
  .add(pageIn())
  .add(tilesOut())
  .add(mainTileIn())
  .add(pulse())
  .add(requested())
  .add(clearStage());

export default masterTimelineN1;
