// Animation S
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

function navBarSlideDown() {
  const timeline = gsap.timeline();
  
  timeline
    .from('#s1__nav-bar', 0.5, {
      y: '-=36'
    }, '-=0.4')
    .staggerFrom('#s1__page-1__header, #s1__page-1__product, #s1__page-1__content', 1, {
      opacity: 0
    }, -0.1);
  
  return timeline;
}

function clickButton() {
  const timeline = gsap.timeline();
  
  timeline
    .fromTo('#s1__cursor', 1, {
      x: 460,
      y: 252
    }, {
      x: 380,
      y: 208
    })
    .to('#s1__page-1__cart-button', 0.25, {
      opacity: 0.4
    }, '-=0.4');
  
  return timeline;
}

function pageOneFadeOut() {
  const timeline = gsap.timeline();
  
  timeline
    .to('#s1__nav-bar', 0.5, {
      y: '-=36'
    }, '+=0.5')
    .staggerTo('#s1__cursor, #s1__page-1__content, #s1__page-1__product, #s1__page-1__header', 0.5, {
      opacity: 0
    }, 0.2, '-=0.75');
  
  return timeline;
}

function pageTwoFadeIn() {
  const timeline = gsap.timeline();
  
  timeline
    .from('#s1__page-2', 0.5, {
      y: '+=16',
      opacity: 0
    }, '+=0.5')
    .staggerFrom('.s1__page-2__line-item', 1.6, {
      opacity: 0,
      y: '+=10',
      ease: 'Expo.easeOut'
    }, 0.1, '-=0.5');
  
  return timeline;
}

function showBadge() {
  const timeline = gsap.timeline();
  
  timeline.from('#s1__page-2__badge', 0.3, {
    scale: 0.8,
    opacity: 0,
    transformOrigin: '50% 50%'
  }, '+=0.5');
  
  return timeline;
}

function clearStage() {
  const timeline = gsap.timeline();
  
  timeline.to('#s1__page-2', 0.5, {
    y: '+=16',
    opacity: 0
  });
  
  return timeline;
}

const masterTimelineS1 = gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineS1.restart();
    }, 1000);
  }
});

masterTimelineS1
  .add(navBarSlideDown())
  .add(clickButton())
  .add(pageOneFadeOut())
  .add(pageTwoFadeIn())
  .add(showBadge())
  .add(clearStage(), '+=1');

export default masterTimelineS1;
