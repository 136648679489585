// Animation K
import { gsap } from "gsap";

function pageIn() {
  const timeline =  gsap.timeline();

  timeline
    .from("#k1__header-bg", 0.4, {
      scaleX: 0,
    })
    .from(
      "#k1__header-logo",
      0.6,
      {
        opacity: 0,
      },
      "-=0.3"
    )
    .from(
      "#k1__header-title",
      0.5,
      {
        opacity: 0,
        x: "-=10",
      },
      "-=0.4"
    )
    .from(
      "#k1__header-row",
      0.5,
      {
        opacity: 0,
        x: "-=10",
      },
      "-=0.4"
    )
    .from(
      "#k1__img",
      0.5,
      {
        opacity: 0,
        y: "+=10",
      },
      "+=0.4"
    )
    .from("#k1__caption", 0.5, {
      opacity: 0,
      y: "+=10",
    })
    .staggerFrom(
      ".k1__btn",
      0.5,
      {
        opacity: 0,
        y: "+=10",
      },
      0.1
    );

  return timeline;
}

const masterTimelineK1 =  gsap.timeline({
  paused: true,
});

masterTimelineK1.add(pageIn());

export default masterTimelineK1;
