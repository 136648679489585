/* eslint no-shadow:0 */

const Utilities = {};

Utilities.autobind = (self, options) => {
  // https://github.com/sindresorhus/auto-bind
  options = Object.assign({}, options);

  const filter = key => {
    const match = pattern => {
      return typeof pattern === "string" ? key === pattern : pattern.test(key);
    };

    if (options.include) {
      return options.include.some(match);
    }

    if (options.exclude) {
      return !options.exclude.some(match);
    }

    return true;
  };

  for (const key of Object.getOwnPropertyNames(self.constructor.prototype)) {
    const value = self[key];

    if (key !== "constructor" && typeof value === "function" && filter(key)) {
      self[key] = value.bind(self);
    }
  }

  return self;
};

Utilities.client = {
  // https://github.com/ariiiman/s/blob/master/src/Core/Snif.js
  uA: window.navigator.userAgent.toLowerCase(),
  isMobileIE: () => {
    return /iemobile/i.test(Utilities.client.uA);
  },
  isMobileOpera: () => {
    return /opera mini/i.test(Utilities.client.uA);
  },
  isIOS: () => {
    return /iphone|ipad|ipod/i.test(Utilities.client.uA);
  },
  isBlackberry: () => {
    return /blackberry/i.test(Utilities.client.uA);
  },
  isMobileAndroid: () => {
    return /android.*mobile/.test(Utilities.client.uA);
  },
  isAndroid: () => {
    return (
      Utilities.client.isMobileAndroid ||
      (!Utilities.client.isMobileAndroid &&
        /android/i.test(Utilities.client.uA))
    );
  },
  isFirefox: () => {
    return Utilities.client.uA.indexOf("firefox") > -1;
  },
  safari: () => {
    return Utilities.client.uA.match(/version\/[\d.]+.*safari/);
  },
  isSafari: () => {
    return !!Utilities.client.safari && !Utilities.client.isAndroid;
  },
  isSafariOlderThan8: () => {
    const limit = 8;
    let version = limit;

    if (Utilities.client.isSafari) {
      const versionWithVersionWord = Utilities.client.safari[0].match(
        /version\/\d{1,2}/
      );
      version = +versionWithVersionWord[0].split("/")[1];
    }

    return version < limit;
  },

  isIEolderThan11: () => {
    return Utilities.client.uA.indexOf("msie") > -1;
  },
  isIE11: () => {
    return window.navigator.appVersion.indexOf("Trident/") > 0;
  },
  isIE: () => {
    return Utilities.client.isIEolderThan11 || Utilities.client.isIE11;
  },
  isEdge: () => {
    return /Edge\/\d./i.test(Utilities.client.uA);
  },
  isMac: () => {
    return window.navigator.platform.toLowerCase().indexOf("mac") > -1;
  },
  isMobile: () => {
    return (
      Utilities.client.isMobileAndroid ||
      Utilities.client.isBlackberry ||
      Utilities.client.isIOS ||
      Utilities.client.isMobileOpera ||
      Utilities.client.isMobileIE
    );
  },
  isTouch: () => {
    return "ontouchstart" in window;
  },
};

Utilities.index = (el, collection) => {
  return Array.prototype.slice.call(collection).indexOf(el);
};

Utilities.is = {
  // https://github.com/ariiiman/s/blob/master/src/Core/Is.js
  arr: v => {
    return v.constructor === Array;
  },
  def: v => {
    return v !== undefined;
  },
  fn: v => {
    return typeof v === "function";
  },
  obj: v => {
    return v === Object(v);
  },
  str: v => {
    return typeof v === "string";
  },
  undef: v => {
    return v === undefined;
  },
};

Utilities.keys = (obj, callback) => {
  if (Utilities.is.obj(obj)) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        callback(key);
      }
    }
  }
};

Utilities.log = (output, type = null) => {
  const style = {
    white: "color: #fff",
    green: "color: #b5e889",
    timer: "font-style: italic; color: #c791ec",
  };

  if (typeof output === "string") {
    switch (type) {
      case "success":
        console.log(`✅ %c${output}`, style.green);
        break;
      case "error":
        console.error(`‼️ ${output}`);
        break;
      case "warn":
        console.warn(`⚠️ ${output}`);
        break;
      default:
        console.log(output);
        break;
    }
  } else {
    console.dir(output);
  }
};

Utilities.merge = (fnA, fnB) => {
  return (...args) => {
    fnA(...args);
    fnB(...args);
  };
};

Utilities.parent = (el, tagName) => {
  while (el.parentNode) {
    el = el.parentNode;

    if (el.tagName === tagName.toUpperCase()) {
      return el;
    }
  }

  return null;
};

Utilities.raf = callback => {
  const raf =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    function(cb) {
      window.setTimeout(cb, 1000 / 60);
    };

  raf.call(window, () => {
    return callback();
  });
};

Utilities.rand = (min, max, decimals = 3) => {
  return parseFloat((Math.random() * (max - min) + min).toFixed(decimals));
};

Utilities.rect = el => {
  return el.getBoundingClientRect();
};

Utilities.reflow = (el, callback) => {
  return el.offsetWidth ? callback() : callback();
};

Utilities.resize = () => {
  const cbs = [];
  let running = false;

  function add(cb) {
    if (cb) {
      cbs.push(cb);
    }
  }

  function run() {
    cbs.forEach(cb => {
      cb();
    });

    running = false;
  }

  function resize() {
    if (!running) {
      running = true;

      Utilities.raf(run);
    }
  }

  return {
    add: cb => {
      if (!cbs.length) {
        window.addEventListener("resize", resize);
      }
      add(cb);
    },
  };
};

Utilities.serialize = form => {
  // https://code.google.com/archive/p/form-serialize
  if (!form || form.nodeName !== "FORM") {
    return "";
  }

  const q = [];

  for (let i = form.elements.length - 1; i >= 0; i--) {
    if (form.elements[i].name !== "") {
      switch (form.elements[i].nodeName) {
        case "INPUT": {
          switch (form.elements[i].type) {
            case "text":
            case "email":
            case "number":
            case "hidden":
            case "password":
            case "button":
            case "reset":
            case "submit": {
              q.push(
                `${form.elements[i].name}=${encodeURIComponent(
                  form.elements[i].value
                )}`
              );

              break;
            }
            case "checkbox":
            case "radio": {
              if (form.elements[i].checked) {
                q.push(
                  `${form.elements[i].name}=${encodeURIComponent(
                    form.elements[i].value
                  )}`
                );
              }

              break;
            }
            case "file": {
              break;
            }
            default: {
              break;
            }
          }

          break;
        }
        case "TEXTAREA": {
          q.push(
            `${form.elements[i].name}=${encodeURIComponent(
              form.elements[i].value
            )}`
          );

          break;
        }
        case "SELECT": {
          switch (form.elements[i].type) {
            case "select-one": {
              q.push(
                `${form.elements[i].name}=${encodeURIComponent(
                  form.elements[i].value
                )}`
              );

              break;
            }
            case "select-multiple": {
              for (let j = form.elements[i].options.length - 1; j >= 0; j--) {
                if (form.elements[i].options[j].selected) {
                  q.push(
                    `${form.elements[i].name}=${encodeURIComponent(
                      form.elements[i].options[j].value
                    )}`
                  );
                }
              }

              break;
            }
            default: {
              break;
            }
          }

          break;
        }
        case "BUTTON": {
          switch (form.elements[i].type) {
            case "reset":
            case "submit":
            case "button": {
              q.push(
                `${form.elements[i].name}=${encodeURIComponent(
                  form.elements[i].value
                )}`
              );

              break;
            }
            default: {
              break;
            }
          }

          break;
        }
        default: {
          break;
        }
      }
    }
  }

  return q.join("&");
};

Utilities.win = {
  // https://github.com/ariiiman/s/blob/master/src/Window/Win.js
  w: () => {
    return window.innerWidth;
  },
  h: () => {
    return window.innerHeight;
  },
  path: () => {
    return window.location.pathname;
  },
  hostname: () => {
    return window.location.hostname;
  },
  href: () => {
    return window.location.href;
  },
};

Utilities.wrap = (el, wrap) => {
  el.parentNode.insertBefore(wrap, el);
  wrap.appendChild(el);

  return wrap;
};

Utilities.scrollPos = () => {
  return (
    window.pageYOffset ||
    (document.documentElement.clientHeight
      ? document.documentElement.scrollTop
      : document.body.scrollTop) ||
    0
  );
};

Utilities.animationEnd = () => {
  const el = document.createElement("div");
  const events = new Map([
    ["animation", "animationend"],
    ["OAnimation", "oAnimationEnd"],
    ["MozAnimation", "animationend"],
    ["WebkitAnimation", "webkitAnimationEnd"],
  ]);

  for (const key of events.keys()) {
    if (el.style[key] !== undefined) {
      return events.get(key);
    }
  }

  return false;
};

Utilities.transitionEnd = () => {
  const el = document.createElement("div");
  const events = new Map([
    ["transition", "transitionend"],
    ["OTransition", "oTransitionEnd"],
    ["MozTransition", "transitionend"],
    ["WebkitTransition", "webkitTransitionEnd"],
  ]);

  for (const key of events.keys()) {
    if (el.style[key] !== undefined) {
      return events.get(key);
    }
  }

  return false;
};

Utilities.checkStorageSupport = () => {
  const testKey = "test";
  const storage = window.sessionStorage;

  try {
    storage.setItem(testKey, "1");
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
};

export default Utilities;
