// Animation U
import { gsap } from "gsap";
gsap.config({nullTargetWarn: false});

function navBarSlideDown() {
  const timeline = gsap.timeline();

  timeline
    .from('#u1__nav-bar', 0.5, {
      y: '-=36'
    }, '-=0.4');

  return timeline;
}

function showPageContent() {
  const timeline = gsap.timeline();

  timeline
    .from('#u1__page-1__header-bg', 1, {
      scaleX: 0
    })
    .from('#u1__page-1__banner', 1, {
      opacity: 0
    }, '-=0.25')
    .from('#u1__page-1__logo', 0.5, {
      opacity: 0,
      y: '+=10'
    }, '-=0.25')
    .from('#u1__page-1__title', 0.5, {
      opacity: 0,
      x: '-=10'
    }, '-=0.25')
    .from('#u1__page-1__description', 0.4, {
      scaleX: 0,
      transformOrigin: 'top left'
    }, '-=0.25')
    .staggerFrom('.u1__page-1__product-tile', 1.6, {
      opacity: 0,
      y: '+=10',
      ease: 'Expo.easeOut'
    }, 0.1, '-=0.3')
    .staggerFrom('.u1__page-1__bars', 0.5, {
      scaleX: 0,
      transformOrigin: 'top left'
    }, 0.1, '-=1.5')
    .staggerFrom('.u1__page-1__social', 1, {
      opacity: 0,
      y: '+=5',
      ease: 'Expo.easeOut'
    }, 0.1, '-=1');

  return timeline;
}

function clickNotifications() {
  const timeline = gsap.timeline();

  timeline
    .fromTo('#u1__cursor', 1, {
      x: 560,
      y: 44
    }, {
      x: 408,
      y: 12
    });

  return timeline;
}

function pageOneFadeOut() {
  const timeline = gsap.timeline();

  timeline
    .staggerTo('#u1__cursor, #u1__page-1__banner, #u1__page-1__header, #u1__page-1__content', 0.5, {
      opacity: 0
    }, 0.2, '-=0.75');

  return timeline;
}

function pageTwoFadeIn() {
  const timeline = gsap.timeline();

  timeline
    .from('#u1__page-2', 0.5, {
      y: '+=16',
      opacity: 0
    }, '+=0.5')
    .staggerFrom('.u1__page-2__line-item', 1.6, {
      opacity: 0,
      y: '+=10',
      ease: 'Expo.easeOut'
    }, 0.1, '-=0.5');

  return timeline;
}

function clickButton() {
  const timeline = gsap.timeline();

  timeline
    .to('#u1__cursor', 0.001, {
      opacity: 1
    })
    .fromTo('#u1__cursor', 1, {
      x: 352,
      y: 330
    }, {
      x: 291,
      y: 264
    })
    .to('#u1__page-2__fulfill-button', 0.25, {
      opacity: 0.4
    }, '-=0.4');

  return timeline;
}

function pageTwoFadeOut() {
  const timeline = gsap.timeline();

  timeline
    .to('#u1__nav-bar', 0.5, {
      y: '-=36'
    }, '+=0.5')
    .staggerTo('#u1__cursor, #u1__page-2', 0.5, {
      opacity: 0
    }, '-=0.75');

  return timeline;
}

function pageThreeFadeIn() {
  const timeline = gsap.timeline();

  timeline.from('#u1__page-3', 0.5, {
    y: '+=16',
    opacity: 0
  }, '+=0.5');

  return timeline;
}

function clearStage() {
  const timeline = gsap.timeline();

  timeline.to('#u1__page-3', 0.5, {
    y: '+=16',
    opacity: 0
  });

  return timeline;
}

const masterTimelineU1 = gsap.timeline({
  paused: true,
  onComplete: () => {
    window.setTimeout(() => {
      masterTimelineU1.restart();
    }, 1000);
  }
});

masterTimelineU1
  .add(navBarSlideDown())
  .add(showPageContent())
  .add(clickNotifications(), '-=0.5')
  .add(pageOneFadeOut())
  .add(pageTwoFadeIn(), '-=0.5')
  .add(clickButton())
  .add(pageTwoFadeOut())
  .add(pageThreeFadeIn())
  .add(clearStage(), '+=1.25');

export default masterTimelineU1;
