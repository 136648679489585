import { gsap } from "gsap";
import SplitText from "../vendor/SplitText";

const CYCLES = 2;

class Counter {
  constructor(el) {
    this.el = document.createElement("div");
    this.el.classList.add("counter");
    this.el.textContent = el.textContent;

    this.original = el;
    this.original.style.opacity = 0;
    // this.original.style.lineHeight = "1em";

    this.digits = Counter.getChars(this.el);
    this.digits.forEach(Counter.buildDigit);
  }

  run(callback = () => {}) {
    const style = window.getComputedStyle(this.original);

    this.original.innerHTML = "";
    this.original.style.opacity = null;
    this.original.appendChild(this.el);

    this.digits.forEach((digit, i) => {
      const track = digit.querySelector(".counter__track");
      const delay =
        i === this.digits.length - 1
          ? 0.1 * (this.digits.length + 3)
          : 0.1 * (this.digits.length - i);

      digit.style.fontSize = style.fontSize;
      digit.style.lineHeight = style.lineHeight;

      if (track) {
        const timeline =  gsap.timeline();

        timeline.fromTo(
          track,
          0.2 * this.digits.length,
          {
            yPercent: 0,
          },
          {
            yPercent: -100 + (1 / CYCLES) * 10,
            delay,
            ease: "Power4.easeOut",
            onStart: () => {
              track.classList.add("counter__track--running");
              callback();
            },
          }
        );
      } else {
        gsap.to(digit.querySelector(".counter__char"), 1, {
          yPercent: -100,
          delay,
          ease: "Expo.easeOut",
        });
      }
    });
  }

  static buildDigit(digit, i) {
    const value = parseInt(digit.textContent, 10);

    const spacer = document.createElement("span");
    spacer.classList.add("counter__spacer");
    spacer.textContent = i === 0 ? 0 : digit.textContent;

    digit.innerHTML = "";
    digit.appendChild(spacer);

    if (!isNaN(value)) {
      const track = document.createElement("span");
      track.classList.add("counter__track");
      digit.appendChild(track);

      const span = document.createElement("span");

      for (let j = 0; j < CYCLES; j++) {
        for (let k = 0; k < 10; k++) {
          span.textContent = (value + 1 + k) % 10;
          track.appendChild(span.cloneNode(true));
        }
      }
    } else {
      const char = document.createElement("span");
      char.classList.add("counter__char");
      char.textContent = digit.textContent;
      digit.appendChild(char);
    }
  }

  static getChars(el) {
    const split = new SplitText(el, {
      type: "chars",
      charsClass: "counter__digit",
    });

    split.chars.forEach(char => {
      char.removeAttribute("style");
    });

    return split.chars;
  }
}

export default Counter;
