import { gsap } from "gsap";
import U from "./Utilities";

class CustomerLogos {
  constructor(el) {
    U.autobind(this);

    this.el = el;
    this.heading = this.el.querySelector(".customer-logos__heading");
    this.items = [];
    this.collection = el.querySelector(".customer-logos__collection");

    el.querySelectorAll(".customer-logos__item").forEach(item => {
      if (item.clientWidth) {
        this.items.push(item);
      }
    });

    this.logosReady = new Promise(allResolved => {
      window.requestIdleCallback(() => {
        const imgPromises = window.hiddenCustomerLogos.map(
          logoUrl =>
            new Promise(resolve => {
              const img = document.createElement("img");
              img.src = logoUrl;
              img.onload = () => resolve(img);
            })
        );
        Promise.all(imgPromises)
          .then(imgs => {
            imgs.forEach(img => {
              this.collection.appendChild(img);
            });
          })
          .then(allResolved);
      });
    });
  }

  start() {
    this.items.forEach(item => {
      if (U.client.isIE()) {
        const svg = item.children[0];
        if (svg.clientWidth > item.clientWidth) {
          svg.style.width = "100%";
        }
      }
    });
    window.setTimeout(() => {
      this.items.forEach((item, index) => {
        this.logosReady.then(() => {
          item.addEventListener("click", () => {
            this.update(item);
          });
        });
        const svg = item.children[0];
        svg.classList.remove(`initial-logo-${index}`);
      });
    }, 1500);

    if (window.hiddenCustomerLogos.length) {
      this.timeout = window.setTimeout(
        () => this.logosReady.then(this.update),
        3000
      );
    }
  }

  update(item) {
    window.clearTimeout(this.timeout);

    if (!item) {
      item = this.getRandomItem();
    }

    const svg = this.getRandomLogo();
    svg.style.opacity = null;

    gsap.to(item.children[0], 0.3, {
      y: item.clientHeight,
      ease: "Quad.easeInOut",
      onComplete: () => {
        const oldImage = item.children[0];
        this.collection.appendChild(oldImage);
        oldImage.style.width = "auto";
        this.appendLogoToItem(svg, item);

        gsap.fromTo(
          svg,
          1,
          {
            y: item.clientHeight,
          },
          {
            y: 0,
            ease: "Expo.easeOut",
            delay: 0.25,
            onComplete: () => {
              this.timeout = window.setTimeout(this.update, 1500);
            },
          }
        );
      },
    });
  }

  getRandomItem() {
    return this.items[U.rand(0, this.items.length - 1, 0)];
  }

  getRandomLogo() {
    return this.collection.children[
      U.rand(0, this.collection.children.length - 1, 0)
    ];
  }

  appendLogoToItem(svg, item) {
    item.appendChild(svg);
    if (U.client.isIE() && svg.clientWidth > item.clientWidth) {
      svg.style.width = "100%";
    }
  }
}

export default CustomerLogos;
